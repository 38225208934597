import Container from "components/globals/Container";
import {
  getApplicationData,
  setCurrentUser,
  setCurrentUserAuthToken,
} from "helpers/storage";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { post } from "utilities/network";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    execute: login,
    status,
    value,
  } = useAsync(
    () =>
      post(`auth/login`, {
        email,
        password,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.user) {
      setCurrentUserAuthToken(value?.data?.accessToken);
      setCurrentUser({
        _id: value?.data?.user?._id,
        email: value?.data?.user?.email,
        firstName: value?.data?.user?.firstName,
        lastName: value?.data?.user?.lastName,
      });
      const visaData = getApplicationData();
      if (visaData?.visaID) {
        navigate(`/${publicRoutes.apply}?visa=${visaData?.visaID}`);
      } else {
        navigate(`/${publicRoutes.dashboard}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <main className="flex flex-col h-screen px-5 bg-white md:px-0">
        <header>
          <Helmet>
            <title>Visatify - Login</title>
          </Helmet>

          <div className="px-10 py-5 text-left">
            <a className="text-2xl font-bold" href="/">
              <img
                src="/assets/images/visatify.png"
                className="object-contain w-40"
                alt="Visatify"
              />
            </a>
          </div>
        </header>
        <section className="mt-20 self-center border border-black border-opacity-20 px-5 py-16 flex items-center flex-col w-full md:w-[50%] lg:w-[40%] rounded">
          <h3 className="self-start ml-5 text-xl font-medium">Welcome Back</h3>
          <form
            action=""
            method="post"
            className="flex flex-col items-center w-full mt-14"
          >
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="email">
                Email Address
              </label>
              <input
                className="w-full px-3 py-2 mt-2 text-black border border-black rounded outline-none border-opacity-20"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%]">
              <label className="cursor-pointer" htmlFor="password">
                Password
              </label>
              <input
                className="w-full px-3 py-2 mt-2 text-black border border-black rounded outline-none border-opacity-20"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between w-[90%] md:w-[70%] mt-1">
              <div className="flex items-center">
                <input type="checkbox" id="remember" className="mr-2" />
                <label htmlFor="remember">Remember Me</label>
              </div>
              <div>
                <a>Forgot Password?</a>
              </div>
            </div>

            <div className="mt-14  w-[90%] md:w-[70%]">
              <button
                type="button"
                onClick={login}
                className="block w-full px-16 py-2 text-white rounded-lg bg-primary"
              >
                Log In
              </button>
              <div className="mt-5">
                Don't have an account?{" "}
                <a className="text-success" href={publicRoutes.register}>
                  Sign Up
                </a>
              </div>
            </div>
          </form>
        </section>
      </main>
    </Container>
  );
};

export default Login;
