import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { adminRoutes } from "routes/routes";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";
import countries from "utils/countries";

const options = Object.keys(countries).map((code) => ({
  value: code,
  label: countries[code],
}));

let allOptions = [...options];
allOptions.unshift({ value: "*", label: "Select All" });

const Option = (props: any) => (
  <components.Option {...props}>
    <div className="flex items-center">
      {props.value !== "*" && (
        <img
          className="mr-2 w-4"
          src={`/assets/flags/${props.value.toLowerCase()}.svg`}
        />
      )}
      <span>{props.children}</span>
    </div>
  </components.Option>
);

const MultiValueLabel = (props: any) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center">
      {props?.data?.value !== "*" && (
        <img
          className="mr-2 w-4"
          src={`/assets/flags/${props?.data?.value.toLowerCase()}.svg`}
        />
      )}
      <span>{props.children}</span>
    </div>
  </components.MultiValueLabel>
);

const entryTypes = ["Multiple", "Single"];
const units = ["Day", "Month", "Year"];
const types = [
  "Tourist",
  "Business",
  "Medical",
  "Work",
  "Digital Nomad",
  "Study",
];

const AddVisa: React.FC = () => {
  const navigate = useNavigate();
  const [requirements, setRequirements] = useState([""]);
  const [name, setName] = useState("");
  const [entryType, setEntryType] = useState(entryTypes[0]);
  const [validity, setValidity] = useState("");
  const [validityUnit, setValidityUnit] = useState(units[0]);
  const [stay, setStay] = useState("");
  const [stayUnit, setStayUnit] = useState(units[0]);
  const [country, setCountry] = useState("");
  const [countriesApplicableTo, setCountriesApplicableTo] = useState<
    typeof options
  >([]);
  const [governmentFee, setGovernmentFee] = useState("");
  const [processingFee, setProcessingFee] = useState("");
  const [info, setInfo] = useState("");
  const [type, setType] = useState(types[0]);

  const addRequirement = () => {
    setRequirements((a) => [...a, ""]);
  };

  const removeRequirement = (index: number) => {
    let newRequirements = [...requirements];
    newRequirements.splice(index, 1);
    setRequirements(newRequirements);
  };

  const updateRequirement = (value: string, index: number) => {
    let v = [...requirements];
    v[index] = value;
    setRequirements(v);
  };

  const handleDestinationsChange = (value: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option" && option.value === "*") {
      setCountriesApplicableTo(options);
    } else if (
      (action === "deselect-option" && option.value === "*") ||
      (action === "remove-value" && removedValue.value === "*")
    ) {
      setCountriesApplicableTo([]);
    } else {
      setCountriesApplicableTo(value);
    }
  };

  const {
    execute: addNow,
    status,
    value,
  } = useAsync(() => {
    return post(`admin/visa/add`, {
      name,
      entryType,
      type,
      country,
      validity,
      validityUnit,
      stay,
      stayUnit,
      requirements,
      countriesApplicableTo: countriesApplicableTo.map((c) => c.value),
      governmentFee,
      processingFee,
      info,
    });
  }, false);

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Added successfully");
      navigate(`/${adminRoutes.visas}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <AdminHeader title="Visatify - Visas" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5 mb-5">
        <section>
          <div className="mb-10 mt-5 flex justify-between items-center">
            <h2 className="text-xl">Add New Visa</h2>
          </div>
          <form action="" method="post" className="w-full mt-14 flex flex-col">
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="visaName">
                Visa Name
              </label>
              <input
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                type="visaName"
                id="visaName"
                name="visaName"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="type">
                Visa Type
              </label>
              <select
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                id="type"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {types.map((ent) => (
                  <option value={ent} key={ent}>{`${ent} Visa`}</option>
                ))}
              </select>
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="entryType">
                Entry Type
              </label>
              <select
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                id="entryType"
                name="entryType"
                value={entryType}
                onChange={(e) => setEntryType(e.target.value)}
              >
                {entryTypes.map((ent) => (
                  <option value={ent} key={ent}>{`${ent} Entry`}</option>
                ))}
              </select>
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="validity">
                Validity
              </label>
              <div className="flex items-center w-full">
                <input
                  className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-[70%]"
                  type="number"
                  id="validity"
                  name="validity"
                  value={validity}
                  onChange={(e) => setValidity(e.target.value)}
                />
                <select
                  className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-1 w-[30%]"
                  id="validityUnit"
                  name="validityUnit"
                  value={validityUnit}
                  onChange={(e) => setValidityUnit(e.target.value)}
                >
                  {units.map((ent) => (
                    <option key={ent}>{ent}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="stay">
                Duration Of Stay
              </label>
              <div className="flex items-center w-full">
                <input
                  className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-[70%]"
                  type="number"
                  id="stay"
                  name="stay"
                  value={stay}
                  onChange={(e) => setStay(e.target.value)}
                />
                <select
                  className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-1 w-[30%]"
                  id="stayUnit"
                  name="stayUnit"
                  value={stayUnit}
                  onChange={(e) => setStayUnit(e.target.value)}
                >
                  {units.map((ent) => (
                    <option key={ent}>{ent}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor={`visaRequirement-0`}>
                Requirements
              </label>
              {requirements?.map((_, index) => (
                <div
                  key={String(index)}
                  className="flex items-center justify-between gap-x-3 w-full mt-2 mb-2"
                >
                  <input
                    className="border border-black rounded border-opacity-70 outline-none  py-2 px-3 w-[90%]"
                    type="text"
                    id={`visaRequirement-${index}`}
                    name={`visaRequirement-${index}`}
                    onChange={(e) => updateRequirement(e.target.value, index)}
                    value={requirements[index]}
                  />
                  {index === 0 ? (
                    <button
                      type="button"
                      onClick={addRequirement}
                      className="bg-primary text-center rounded-full flex px-4 justify-center items-center py-2 w-[10%]"
                    >
                      <i className="fa fa-plus text-white"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => removeRequirement(index)}
                      className="bg-primary text-center rounded-full flex px-4 justify-center items-center py-2 w-[10%]"
                    >
                      <i className="fa fa-minus text-white"></i>
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="visaName">
                Visa Country
              </label>
              <ReactFlagsSelect
                selected={country}
                onSelect={(code) => setCountry(code)}
                placeholder="Select Country"
                searchable
                id="pickup"
                countries={[]}
                blacklistCountries
                className="bg-white w-full mt-2 border-black border-opacity-70 text-black [&>button]:border-none"
              />
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="visaName">
                Countries Applicable to
              </label>
              <Select
                className="bg-white w-full mt-2 border-black border-opacity-70 text-black"
                value={countriesApplicableTo}
                isMulti
                onChange={handleDestinationsChange}
                options={allOptions}
                isSearchable
                name="destination"
                components={{ Option, MultiValueLabel }}
              />
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="issuingFee">
                Government Issuing Fee ($)
              </label>
              <input
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                type="number"
                id="issuingFee"
                name="issuingFee"
                value={governmentFee}
                onChange={(e) => setGovernmentFee(e.target.value)}
              />
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="processingFee">
                Processing Fee ($)
              </label>
              <input
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                type="number"
                id="processingFee"
                name="processingFee"
                value={processingFee}
                onChange={(e) => setProcessingFee(e.target.value)}
              />
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="moreInfo">
                More Info (Notice)
              </label>
              <textarea
                className="border border-black rounded border-opacity-70 outline-none mt-2 py-2 px-3 w-full"
                id="moreInfo"
                name="moreInfo"
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              />
            </div>

            <div className="w-[90%] md:w-[70%] mt-5">
              <VButton
                onClick={addNow}
                status={status}
                className="bg-primary px-10 md:px-20 py-2 text-white rounded bg-opacity-80 shadow-lg w-full drop-shadow-lg"
              >
                Add Visa
              </VButton>
            </div>
          </form>
        </section>
      </main>
    </Container>
  );
};

export default AddVisa;
