import { httpFetchVisas } from "api/admin/visas";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import { useQuery } from "react-query";
import { adminRoutes } from "routes/routes";
import countries from "utils/countries";

const Visas: React.FC = () => {
  const {
    data: { visas },
  } = useQuery("visas", () => httpFetchVisas(), {
    initialData: { visas: [] },
  });

  return (
    <Container>
      <AdminHeader title="Visatify - Visas" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section>
          <div className="mb-10 mt-5 flex justify-between items-center">
            <h2 className="text-xl">Visas</h2>
            <div className="">
              <a
                href={adminRoutes.addVisa}
                className="bg-primary px-10 md:px-20 py-2 text-white rounded bg-opacity-80 shadow-lg drop-shadow-lg"
              >
                Add New
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-5 py-2 px-2 rounded">
            {visas?.map((visa: any) => (
              <Visa key={visa._id} visa={visa} />
            ))}
          </div>
        </section>
      </main>
    </Container>
  );
};

const Visa: React.FC<{ visa: any }> = ({ visa }) => (
  <a
    href={`${adminRoutes.editVisa}/${visa._id}`}
    className="flex flex-col justify-between gap-y-10 shadow-xl shadow-[#34A9FE17] drop-shadow-xl px-5 py-5 bg-white rounded-lg cursor-pointer"
  >
    <div className="flex gap-x-5">
      <img
        src={`https://flagcdn.com/112x84/${visa.country.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/224x168/${visa.country.toLowerCase()}.png 2x`}
        className="w-20"
        alt={countries[visa.country]}
      ></img>
      <div>
        <h3 className="text-black text-opacity-60 text-lg">
          ({countries[visa.country]})
        </h3>
        <h4>{visa.country}</h4>
      </div>
    </div>
    <div>
      <h3 className="text-black text-opacity-60 text-lg">
        Visa Category (
        <span className="text-sm italic">{visa.entryType} Entry</span>)
      </h3>
      <h4>{visa.type} Visa</h4>
    </div>
  </a>
);
export default Visas;
