import { ReactNode } from "react";
import Spinner from "./Spinner";

interface VButtonProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  status?: string;
  disabled?: boolean;
  type?: "button" | "submit";
}

const VButton: React.FC<VButtonProps> = ({
  children,
  onClick,
  className,
  status,
  disabled = false,
  type = "button",
}) => (
  <button
    type={type}
    className={`rounded-2xl px-5 py-2 ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {status === "pending" ? <Spinner /> : children}
  </button>
);

export default VButton;
