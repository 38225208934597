import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { publicRoutes } from "routes/routes";

const Header: React.FC<{ title: string }> = ({ title }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="flex items-center justify-between w-full py-6 lg:px-14 xl:px-28">
        <div className="ml-5 md:pl-10 lg:pl-0 lg:ml-0">
          <a className="text-4xl font-bold" href="/">
            <img
              src="/assets/images/visatify.png"
              className="object-contain w-40"
              alt="Visatify"
            />
          </a>
        </div>
        <button
          onClick={() => setShowMenu((p) => !p)}
          type="button"
          className="lg:hidden fa fa-bars mr-7 md:mr-10 lg:mr-0"
        ></button>
        <nav
          className={`bg-white lg:bg-transparent absolute lg:relative pt-10 lg:pt-0 flex lg:block items-center flex-col lg:w-auto w-full mt-10 lg:mt-0 h-screen lg:h-auto overflow-hidden top-14 lg:top-auto z-50 ${
            showMenu ? "block" : "hidden lg:block"
          }`}
        >
          <ul className="flex flex-col lg:flex-row items-center gap-x-10 text-[17px]">
            <li className="mb-8 lg:mb-0">
              <a href={publicRoutes.home}>Home</a>
            </li>
            <li className="mb-8 lg:mb-0">
              <a href="">Visa Programs</a>
            </li>
            <li className="mb-8 lg:mb-0">
              <a href="">Travel Insight</a>
            </li>
            <li className="mb-8 lg:mb-0">
              <a href="">Contact Us</a>
            </li>
            <li className="mb-8 xl:ml-40 lg:mb-0">
              <a href={publicRoutes.login}>Log In</a>
            </li>
            <li className="px-2 py-2 rounded-lg bg-primary">
              <a className="px-3 py-2 text-white" href={publicRoutes.register}>
                Get started
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
