import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { getApplicationData } from "helpers/storage";
import useAsync from "hooks/useAsync";
import useGetUser from "hooks/useGetUser";
import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { showDanger } from "utilities/misc";
import { get, post } from "utilities/network";

const initialData = {
  firstName: "",
  middleName: "",
  lastName: "",
  title: "",
  maritalStatus: "",
  gender: "",
  countryOfResidence: "",
  nationality: "",
  dateOfBirth: "",
};

const Start: React.FC = () => {
  const { user } = useGetUser();
  const navigate = useNavigate();
  const [applicants, setApplicants] = useState<any[]>([{ ...initialData }]);
  const [visa, setVisa] = useState<any>({});
  const visaID = new URLSearchParams(useLocation().search).get("visa") || "";

  const addApplicant = () => {
    setApplicants((a) => [...a, { ...initialData }]);
  };

  const removeApplicant = (index: number) => {
    let newApplicants = [...applicants];
    newApplicants.splice(index, 1);
    setApplicants(newApplicants);
  };

  const updateApplicant = (index: number, key: string, value: string) => {
    let newApplicants = [...applicants];
    let toUpdate = newApplicants.find((a, i) => i === index);
    if (toUpdate) {
      toUpdate[key] = value;
      newApplicants[index] = toUpdate;
      setApplicants(newApplicants);
    }
  };

  const goBack = () => {
    navigate(`/${publicRoutes.search}`);
  };

  useEffect(() => {
    if (visaID) {
      if (!user?._id) {
        return navigate(`/${publicRoutes.apply}?visa=${visaID}`);
      }
      search();
    } else {
      goBack();
    }
  }, [visaID]);

  const search = async () => {
    try {
      let result = await get(`visa/${visaID}`);

      const sessionData = getApplicationData();

      if (result.visa && sessionData.date) {
        setVisa(result.visa);
      } else {
        goBack();
      }
    } catch (e) {
      console.log(e);
      goBack();
    }
  };

  const continueNow = () => {
    let notComplete = applicants.some((a) => {
      for (let i in a) {
        if (!a[i] && i !== "middleName") return true;
      }
      return false;
    });
    if (notComplete) {
      return showDanger("Please fill the form completely");
    }
    apply();
  };

  const {
    execute: apply,
    status,
    value,
  } = useAsync(
    () =>
      post(`visa/apply`, {
        visa: visaID,
        applications: applicants,
        date: getApplicationData().date,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.uuid) {
      navigate(`/${publicRoutes.payment}?appId=${value?.data?.uuid}`);
    } else {
      // showDanger("Something went wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <Header title="Visatify - Application Form" />
      <main>
        <section className="flex flex-col w-full px-20 mt-5 lg:mt-5">
          <h2 className="text-lg">Application Form</h2>
        </section>
        <section className="flex flex-col px-5 py-5 lg:pt-8 lg:pb-20 lg:px-32 md:flex-row gap-x-5 gap-y-7 md:gap-y-0">
          <div className="flex flex-col px-5 py-10 bg-white shadow-lg drop-shadow-lg md:w-8/12 md:px-10">
            <form className="flex flex-col" action="" method="post">
              {applicants?.map((applicant, index) => (
                <Form
                  updateApplicant={updateApplicant}
                  applicants={applicants}
                  key={String(index)}
                  index={index}
                  removeApplicant={removeApplicant}
                />
              ))}

              <div className="flex flex-col self-center mt-10 text-center gap-y-5">
                <button
                  type="button"
                  onClick={continueNow}
                  className="px-10 py-3 text-white rounded shadow bg-primary md:px-20 drop-shadow-lg"
                >
                  <span>Continue to payment</span>
                  <i className="ml-5 fa fa-arrow-right"></i>
                </button>
                <button
                  type="button"
                  onClick={addApplicant}
                  className="px-10 py-3 bg-white border-2 border-dashed rounded shadow border-primary md:px-20 text-primary drop-shadow-lg"
                >
                  <span>Add Another Applicant</span>
                  <i className="ml-5 fa fa-plus"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="bg-white py-5 px-5 items-center md:w-4/12 text-left h-[360px] shadow-lg drop-shadow-lg">
            <h3 className="mb-5 text-lg font-medium text-black">
              Product Summary
            </h3>
            <div className="flex justify-between px-2 pb-3 border-b border-b-black border-opacity-20">
              <h4 className="text-black text-opacity-50">{visa.name}</h4>
              <h4 className="text-black text-opacity-50">Price</h4>
            </div>
            <div className="flex justify-between px-2 pb-3 mt-2">
              <h4 className="text-black text-opacity-50">Government Issuing</h4>
              <h4 className="font-medium text-black text-opacity-60">
                ${visa.governmentFee}
              </h4>
            </div>
            <div className="flex justify-between px-2 pb-3 mt-2 border-b border-b-black border-opacity-20">
              <h4 className="text-black text-opacity-50">Processing fee</h4>
              <h4 className="font-medium text-black text-opacity-60">
                ${visa.processingFee}
              </h4>
            </div>
            <div className="flex justify-between px-2 pb-3 mt-2">
              <h4 className="text-black text-opacity-50">Total Cost </h4>
              <h4 className="font-medium text-black text-opacity-60">
                ${visa.governmentFee + visa.processingFee}{" "}
                {applicants.length > 1 && <span> x {applicants.length}</span>}
              </h4>
            </div>
            <div className="mt-5 text-center">
              <button
                type="button"
                onClick={continueNow}
                className="px-16 py-2 text-white rounded shadow-lg bg-primary lg:px-20 bg-opacity-80 drop-shadow-lg"
              >
                Continue
              </button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Container>
  );
};

const Form: React.FC<{
  index: number;
  removeApplicant: (index: number) => void;
  applicants: any;
  updateApplicant: any;
}> = ({ index, removeApplicant, applicants, updateApplicant }) => {
  return (
    <>
      {index > 0 && (
        <>
          <div className="mt-14 mb-5 bg-black bg-opacity-30 h-0.5"></div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => removeApplicant(index)}
              className="text-primary"
            >
              Remove applicant
            </button>
          </div>
        </>
      )}
      <div className="flex flex-col items-start gap-y-10">
        <div className="flex mb-5 gap-x-5">
          <div className="flex items-center">
            <input
              onChange={(e) => updateApplicant(index, "title", "Mr")}
              checked={applicants[index].title === "Mr"}
              className="mr-2"
              type="radio"
              id={`Mr-${index}`}
              name={`title-${index}`}
            />
            <label className="cursor-pointer" htmlFor={`Mr-${index}`}>
              Mr
            </label>
          </div>
          <div className="flex items-center">
            <input
              onChange={(e) => updateApplicant(index, "title", "Mrs")}
              checked={applicants[index].title === "Mrs"}
              className="mr-2"
              type="radio"
              id={`Mrs-${index}`}
              name={`title-${index}`}
            />
            <label className="cursor-pointer" htmlFor={`Mrs-${index}`}>
              Mrs
            </label>
          </div>
          <div className="flex items-center">
            <input
              onChange={(e) => updateApplicant(index, "title", "Ms")}
              checked={applicants[index].title === "Ms"}
              className="mr-2"
              type="radio"
              id={`Ms-${index}`}
              name={`title-${index}`}
            />
            <label className="cursor-pointer" htmlFor={`Ms-${index}`}>
              Ms
            </label>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full md:flex-row gap-y-7 md:gap-y-0 md:gap-x-16">
          <div className="flex flex-col items-start md:w-1/2">
            <label className="cursor-pointer" htmlFor="firstName">
              First Name
            </label>
            <input
              className="w-full px-3 py-2 mt-2 border border-black rounded outline-none border-opacity-20"
              type="text"
              id="firstName"
              name="firstName"
              value={applicants[index].firstName}
              onChange={(e) =>
                updateApplicant(index, "firstName", e.target.value)
              }
            />
          </div>
          <div className="flex flex-col items-start md:w-1/2">
            <label className="cursor-pointer" htmlFor="middleName">
              Middle Name
            </label>
            <input
              className="w-full px-3 py-2 mt-2 border border-black rounded outline-none border-opacity-20"
              type="text"
              id="middleName"
              name="middleName"
              value={applicants[index].lastName}
              onChange={(e) =>
                updateApplicant(index, "lastName", e.target.value)
              }
            />
          </div>
        </div>
        <div className="flex flex-col justify-between w-full md:flex-row gap-y-7 md:gap-y-0 md:gap-x-16">
          <div className="flex flex-col items-start lg:w-1/2">
            <label className="cursor-pointer" htmlFor="surName">
              Surname
            </label>
            <input
              className="w-full px-3 py-2 mt-2 border border-black rounded outline-none border-opacity-20"
              type="text"
              id="surName"
              name="surName"
              value={applicants[index].middleName}
              onChange={(e) =>
                updateApplicant(index, "middleName", e.target.value)
              }
            />
          </div>
          <div className="flex flex-col items-start lg:w-1/2">
            <p>Marital Status</p>
            <div className="flex mt-3 gap-x-5">
              <div className="flex items-center ">
                <input
                  className="mr-2"
                  type="radio"
                  id={`Single-${index}`}
                  name={`maritalStatus-${index}`}
                  onChange={(e) =>
                    updateApplicant(index, "maritalStatus", "Single")
                  }
                  checked={applicants[index].maritalStatus === "Single"}
                />
                <label className="cursor-pointer" htmlFor={`Single-${index}`}>
                  Single
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className="mr-2"
                  type="radio"
                  id={`Married-${index}`}
                  name={`maritalStatus-${index}`}
                  onChange={(e) =>
                    updateApplicant(index, "maritalStatus", "Married")
                  }
                  checked={applicants[index].maritalStatus === "Married"}
                />
                <label className="cursor-pointer" htmlFor={`Married-${index}`}>
                  Married
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full md:flex-row gap-y-7 md:gap-y-0 md:gap-x-16">
          <div className="flex flex-col items-start lg:w-1/2">
            <p>Gender</p>
            <div className="flex mt-3 gap-x-5">
              <div className="flex items-center ">
                <input
                  onChange={(e) => updateApplicant(index, "gender", "Male")}
                  checked={applicants[index].gender === "Male"}
                  className="mr-2"
                  type="radio"
                  id={`Male-${index}`}
                  name={`gender-${index}`}
                />
                <label className="cursor-pointer" htmlFor={`Male-${index}`}>
                  Male
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className="mr-2"
                  type="radio"
                  id={`Female-${index}`}
                  name={`gender-${index}`}
                  onChange={(e) => updateApplicant(index, "gender", "Female")}
                  checked={applicants[index].gender === "Female"}
                />
                <label className="cursor-pointer" htmlFor={`Female-${index}`}>
                  Female
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start lg:w-1/2">
            <label className="cursor-pointer" htmlFor="residence">
              Country Of Residence
            </label>
            <ReactFlagsSelect
              selected={applicants[index].countryOfResidence}
              onSelect={(code) =>
                updateApplicant(index, "countryOfResidence", code)
              }
              placeholder="Select Country"
              searchable
              id="residence"
              countries={[]}
              blacklistCountries
              className="bg-white text-black [&>button]:border-none mt-1 border border-black border-opacity-20 w-full rounded"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between w-full md:flex-row gap-y-7 md:gap-y-0 md:gap-x-16">
          <div className="flex flex-col items-start md:w-1/2">
            <label className="cursor-pointer" htmlFor="dateOfBirth">
              Date of Birth
            </label>
            <input
              className="w-full px-3 py-2 mt-2 border border-black rounded outline-none border-opacity-20"
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={applicants[index].dateOfBirth}
              onChange={(e) =>
                updateApplicant(index, "dateOfBirth", e.target.value)
              }
            />
          </div>
          <div className="flex flex-col items-start md:w-1/2">
            <label className="cursor-pointer" htmlFor="nationality">
              Nationality
            </label>
            <ReactFlagsSelect
              selected={applicants[index].nationality}
              onSelect={(code) => updateApplicant(index, "nationality", code)}
              placeholder="Select Country"
              searchable
              id="nationality"
              countries={[]}
              blacklistCountries
              className="bg-white text-black [&>button]:border-none mt-1 border border-black border-opacity-20 w-full rounded"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;
