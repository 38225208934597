import { httpFetchStats } from "api/admin/user";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const {
    data: { users, visas, applications, processing },
  } = useQuery("stats", () => httpFetchStats(), {
    initialData: { users: 0, visas: 0, applications: 0, processing: 0 },
  });
  return (
    <Container>
      <AdminHeader title="Visatify Admin - Dashboard" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section>
          <div className="flex items-center justify-between mt-5 mb-10">
            <h2 className="text-xl font-light">Statistics</h2>
          </div>
          <div className="grid grid-cols-1 px-2 py-2 rounded md:grid-cols-2 lg:grid-cols-4 gap-y-5 gap-x-5">
            <div
              onClick={() => navigate(`/${adminRoutes.applications}`)}
              className="flex items-center justify-center cursor-pointer"
            >
              <div className="w-2/6 bg-[#FF5A00] h-full flex items-center text-white justify-center py-5 rounded-l-xl">
                <i className="text-2xl fa fa-file-invoice"></i>
              </div>
              <div className="w-4/6 h-full px-5 py-5 bg-white rounded-r-xl">
                <h3 className="mb-1 text-xl font-medium"> {applications}</h3>
                <p>Applications</p>
              </div>
            </div>

            <div
              onClick={() => navigate(`/${adminRoutes.users}`)}
              className="flex items-center justify-center cursor-pointer"
            >
              <div className="flex items-center justify-center w-2/6 h-full py-5 text-white bg-primary rounded-l-xl">
                <i className="text-2xl fa fa-user"></i>
              </div>
              <div className="w-4/6 h-full px-5 py-5 bg-white rounded-r-xl">
                <h3 className="mb-1 text-xl font-medium"> {users}</h3>
                <p>Users</p>
              </div>
            </div>

            <div
              onClick={() => navigate(`/${adminRoutes.visas}`)}
              className="flex items-center justify-center cursor-pointer"
            >
              <div className="flex items-center justify-center w-2/6 h-full py-5 text-white bg-[#14EC99] rounded-l-xl">
                <i className="text-2xl fa fa-passport"></i>
              </div>
              <div className="w-4/6 h-full px-5 py-5 bg-white rounded-r-xl">
                <h3 className="mb-1 text-xl font-medium"> {visas}</h3>
                <p>Visas</p>
              </div>
            </div>
            <div
              onClick={() => navigate(`/${adminRoutes.applications}`)}
              className="flex items-center justify-center cursor-pointer"
            >
              <div className="flex items-center justify-center w-2/6 h-full py-5 text-white bg-[#012169] rounded-l-xl">
                <i className="text-2xl fa fa-spinner"></i>
              </div>
              <div className="w-4/6 h-full px-5 py-5 bg-white rounded-r-xl">
                <h3 className="mb-1 text-xl font-medium"> {processing || 0}</h3>
                <p>Processing</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Container>
  );
};

export default Dashboard;
