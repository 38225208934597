import { httpFetchMyApplications } from "api/user/visas";
import Container from "components/globals/Container";
import DashboardHeader from "components/globals/DashboardHeader";
import Status from "components/ui/Status";
import useGetUser from "hooks/useGetUser";
import { useQuery } from "react-query";
import { publicRoutes } from "routes/routes";
import countries from "utils/countries";

const VisaStatus: React.FC = () => {
  const { user } = useGetUser();
  const {
    data: { applications },
  } = useQuery("my-applications", () => httpFetchMyApplications(60), {
    initialData: { applications: [] },
  });

  return (
    <Container>
      <DashboardHeader title="Visatify - Applications" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section>
          <h2 className="mt-5 mb-10 text-xl font-light">Applications</h2>
          <div className="overflow-scroll ">
            <table className="table w-full">
              <thead className="text-sm md:text-base">
                <tr className="bg-[#1783E1] text-white">
                  <td className="px-2 py-4 font-light">Applicant Name</td>
                  <td className="px-2 py-4 font-light">Email</td>
                  <td className="px-2 py-4 font-light">Nationality</td>
                  <td className="px-2 py-4 font-light">Visa Type</td>
                  <td className="px-2 py-4 font-light">Status</td>
                  <td className="px-2 py-4 font-light">Action</td>
                </tr>
              </thead>
              <tbody className="bg-[#34A9FE17] p-4 text-sm md:text-base">
                {applications.map((application: any) => (
                  <tr key={application._id} className="bg-white shadow-lg">
                    <td className="px-3 py-5 font-light">{`${application.firstName} ${application.lastName}`}</td>
                    <td className="px-3 py-5 font-light">
                      {application.email || user?.email}
                    </td>
                    <td className="px-3 py-5 font-light">
                      {countries[application?.nationality]}
                    </td>
                    <td className="px-3 py-5 font-light">
                      {application?.visa?.type} Visa
                    </td>
                    <td className="py-5 font-light">
                      <Status status={application?.status} />
                    </td>
                    <td className="py-5 pr-2 font-light">
                      <a
                        className="p-2 border rounded"
                        href={`${publicRoutes.visaDocuments}/${application._id}`}
                      >
                        Manage
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default VisaStatus;
