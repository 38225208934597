import { get } from "utilities/network";

export const httpFetchVisas = (limit: number = 50) => {
  return get(`admin/visa/fetch?limit=${limit}`);
};

export const httpFetchApplications = (limit: number = 50, search: string) => {
  return get(
    `admin/visa/applications?limit=${limit}${search ? "&search=" + search : ""}`
  );
};

export const httpFetchUserApplications = (userID: string) => {
  return get(`admin/visa/user-applications/${userID}`);
};

export const httpGetApplication = (applicationID: string) => {
  return get(`admin/visa/application/${applicationID}`);
};
