import { httpFetchUserApplications } from "api/admin/visas";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const UserApplications: React.FC = () => {
  const { userID } = useParams();
  const navigate = useNavigate();

  const {
    data: { applications, user },
  } = useQuery(
    ["user-applications", userID],
    () => httpFetchUserApplications(userID as string),
    {
      initialData: { applications: [], user: {} },
    }
  );

  const selectApp = (ap: any) => {
    navigate(`/${adminRoutes.viewApplication}/${ap._id}`);
  };

  return (
    <Container>
      <AdminHeader title="Visatify Admin - User Applications" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section>
          <div className="flex items-center justify-between mt-5 mb-10">
            <h2 className="text-xl">
              {`${user.firstName || ""} ${user?.lastName || ""}'s`} Applications
            </h2>
            <div className=""></div>
          </div>
          <div className="">
            <table className="table w-full">
              <thead className="text-sm border-b-2 border-b-black md:text-base">
                <tr>
                  <th className="py-2">User </th>
                  <th className="py-2">Visa </th>
                  <th className="py-2">Date Applied</th>
                  <th className="py-2">Date Issued</th>
                  <th className="py-2">Status</th>
                  <th className="px-2 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm md:text-base">
                {applications?.map((application: any) => (
                  <tr key={application._id} className="bg-white shadow-lg">
                    <td className="px-3 py-2">{`${
                      application?.user?.firstName || ""
                    } ${application?.user?.lastName || ""}`}</td>
                    <td className="px-3 py-2">{application.visa.name}</td>
                    <td className="px-3 py-2">
                      {DateTime.fromISO(application.regDate).toFormat("ff")}
                    </td>
                    <td className="px-3 py-2">
                      {application.dateIssued
                        ? DateTime.fromISO(application.dateIssued).toFormat(
                            "ff"
                          )
                        : ""}
                    </td>
                    <td className="px-3 py-2">{application.status}</td>
                    <td className="px-3 py-4">
                      <button
                        onClick={() => selectApp(application)}
                        className="px-3 py-1 text-black border rounded text-opacity-70"
                        type="button"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default UserApplications;
