import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useLocation } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { get } from "utilities/network";
import countries from "utils/countries";

const SearchResults: React.FC = () => {
  const p = new URLSearchParams(useLocation().search).get("country") || "";
  const d = new URLSearchParams(useLocation().search).get("destination") || "";
  const [visas, setVisas] = useState([]);

  const [pickup, setPickup] = useState(p);
  const [destination, setDestination] = useState(d);

  useEffect(() => {
    if (pickup && destination) {
      search();
    }
  }, [pickup, destination]);

  const search = async () => {
    try {
      let result = await get(
        `visa/search?country=${pickup}&destination=${destination}`
      );
      setVisas(result.visas);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Header title="Visa Search Results" />
      <main className="flex flex-col">
        <aside className="bg-primary md:px-10 lg:px-40 py-10 text-white text-left relative">
          <h2 className="px-5 md:px-0">Search for Visa Information</h2>
          <div className="flex flex-wrap md:flex-nowrap gap-x-3 md:gap-x-5 lg:gap-x-10 items-end mt-8 justify-center md:justify-start gap-y-5 md:gap-y-0">
            <div className="md:w-[40%]">
              <p className="mb-2">Your Citizenship</p>
              <ReactFlagsSelect
                selected={pickup}
                onSelect={(code) => setPickup(code)}
                placeholder="Select Country"
                searchable
                id="pickup"
                countries={[]}
                blacklistCountries
                className="bg-white text-black [&>button]:border-none"
              />
            </div>
            <div className="md:w-[40%]">
              <p className="mb-2">Your Destination</p>
              <ReactFlagsSelect
                selected={destination}
                onSelect={(code) => setDestination(code)}
                placeholder="Select Country"
                searchable
                id="destination"
                countries={[]}
                blacklistCountries
                className="bg-white text-black [&>button]:border-none"
                selectButtonClassName="border-none"
              />
            </div>
          </div>
        </aside>
        <section className="py-20 px-3 md:px-5 lg:px-32 flex flex-col items-center">
          {visas.map((visa: any) => (
            <Visa visa={visa} key={visa._id} />
          ))}
        </section>
      </main>
      <Footer />
    </Container>
  );
};

const Visa: React.FC<{ visa: any }> = ({ visa }) => (
  <div className="flex flex-col w-full shadow-lg drop-shadow-lg mb-5 md:mb-10">
    <div className="bg-[#34A9FE17] flex w-full py-4 px-5 lg:px-14 justify-between">
      <h3 className="text-black font-medium text-opacity-50 lg:text-xl">
        Visa Category
      </h3>
      <h3 className="text-black font-medium text-opacity-50 lg:text-xl">
        Visa Details
      </h3>
      <h3 className="text-black  hidden md:block font-medium text-opacity-50 lg:text-xl">
        Validity Requirement
      </h3>
      <h3 className="text-black hidden md:block font-medium text-opacity-50 lg:text-xl">
        Gov’t Fee
      </h3>
    </div>
    <div className="bg-white flex w-full py-5 lg:py-10 px-2 md:px-5 lg:px-10 items-center justify-between flex-wrap">
      <div>
        <h4 className="md:text-lg mb-4">{visa.name}</h4>
        <img
          src={`https://flagcdn.com/112x84/${visa.country.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/224x168/${visa.country.toLowerCase()}.png 2x`}
          width="112"
          height="84"
          alt={countries[visa.country]}
        ></img>
      </div>
      <div className="text-left">
        <div className="mb-4">
          <h4 className="text-black text-opacity-50 text-lg mb-1">
            Validity Period
          </h4>
          <p>
            <i className="fa fa-calendar-days text-primary text-opacity-70 mr-2"></i>
            <span className="text-black text-opacity-70">
              {visa.validity} {visa.validityUnit}s after issuance
            </span>
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-black text-opacity-50 text-lg mb-1">
            Number of Entries
          </h4>
          <p>
            <i className="fa fa-refresh text-primary text-opacity-70 mr-2"></i>
            <span className="text-black text-opacity-70">
              {visa.entryType} Entries
            </span>
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-black text-opacity-50 text-lg mb-1">Stay</h4>
          <p>
            <i className="fa fa-clock text-primary text-opacity-70 mr-2"></i>
            <span className="text-black text-opacity-70">
              {visa.stay} {visa.stayUnit}s per entry
            </span>
          </p>
        </div>
      </div>
      <div className="bg-[#34A9FE17] md:hidden mb-2 flex w-full py-4 px-5 lg:px-14 justify-between">
        <h3 className="text-black  md:hidden block font-medium text-opacity-50 lg:text-xl">
          Validity Requirement
        </h3>
        <h3 className="text-black md:hidden block font-medium text-opacity-50 lg:text-xl">
          Gov’t Fee
        </h3>
      </div>
      <div className="text-left">
        {visa.requirements?.map((requirement: string) => (
          <h4 key={requirement} className="text-lg mb-4">
            <i className="fa fa-book-bookmark text-base text-primary text-opacity-70 mr-2"></i>
            <span className="text-black text-opacity-70">{requirement}</span>
          </h4>
        ))}
      </div>
      <div className="">
        <h4 className="font-black text-3xl md:text-5xl lg:text-6xl mb-5">
          ${visa.governmentFee}
        </h4>
        <a
          href={`${publicRoutes.apply}?visa=${visa._id}`}
          className="inline-block py-2 lg:py-4 px-4  lg:px-16 bg-primary text-white"
        >
          Get Started
        </a>
      </div>
    </div>
  </div>
);

export default SearchResults;
