import Container from "components/globals/Container";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import { showDanger, showSuccess, validatePassword } from "utilities/misc";
import { post } from "utilities/network";

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [fullName, setUserName] = useState("");
  const {
    execute: register,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/auth/register`, {
        email,
        password,
        fullName,
      }),
    false
  );

  const handleSubmit = () => {
    if (!validatePassword(password)) {
      return showDanger("Password should be at least 6 characters long");
    }
    register();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Registration Successful");
      navigate(`/${adminRoutes.login}`, { state: { registration: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <main className="bg-black bg-opacity-80 text-white h-screen flex flex-col px-5 md:px-0">
        <header>
          <Helmet>
            <title>Visatify - Admin Register</title>
          </Helmet>

          <div className="text-left py-5 px-5 md:px-10">
            <a className="text-2xl font-bold" href="/">
              <img
                src="/assets/images/visatify.png"
                className="object-contain w-40"
                alt="Visatify"
              />
            </a>
          </div>
        </header>
        <section className="mt-20 self-center border border-white border-opacity-20 px-5 py-16 flex items-center flex-col w-full md:w-[50%] lg:w-[40%] rounded">
          <h3 className="text-xl font-medium self-start ml-5">
            Admin Register
          </h3>
          <form
            action=""
            method="post"
            className="w-full mt-14 flex flex-col items-center"
          >
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="name">
                Name
              </label>
              <input
                className="border border-white rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full text-black"
                type="name"
                id="name"
                name="name"
                value={fullName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="email">
                Email Address
              </label>
              <input
                className="border border-white rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full text-black"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%]">
              <label className="cursor-pointer" htmlFor="password">
                Password
              </label>
              <input
                className="border border-white rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full text-black"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mt-14 w-[90%] md:w-[70%]">
              <button
                type="button"
                onClick={handleSubmit}
                className="py-2 w-full block px-16 bg-primary text-white rounded-lg"
              >
                Register
              </button>
            </div>
          </form>
        </section>
      </main>
    </Container>
  );
};

export default Register;
