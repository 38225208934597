import AdminSidebar from "components/ui/AdminSidebar";
import useGetAdmin from "hooks/useGetAdmin";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const AdminHeader: React.FC<{ title: string }> = ({ title }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { admin } = useGetAdmin();
  return (
    <header className="relative h-full">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section className="flex justify-between md:ml-[12%] py-3 px-5 items-center border-b border-b-white border-opacity-20 bg-black bg-opacity-80 text-white">
        <div></div>
        <div className="flex gap-x-5 items-center">
          <span className="">{admin.fullName}</span>
          <img src="/assets/images/wayne.svg" alt={admin.fullName} />
          <button
            onClick={() => setShowMenu((p) => !p)}
            type="button"
            className="md:hidden fa fa-bars"
          ></button>
        </div>
      </section>
      <AdminSidebar showMenu={showMenu} />
    </header>
  );
};

export default AdminHeader;
