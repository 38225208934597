import { httpFetchMyApplications } from "api/user/visas";
import Container from "components/globals/Container";
import DashboardHeader from "components/globals/DashboardHeader";
import useGetUser from "hooks/useGetUser";
import { useQuery } from "react-query";
import countries from "utils/countries";

const Profile: React.FC = () => {
  const { user } = useGetUser();
  const {
    data: { applications },
  } = useQuery("my-applications", () => httpFetchMyApplications(60), {
    initialData: { applications: [] },
  });

  return (
    <Container>
      <DashboardHeader title="Visatify - Profile" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <h2 className="text-xl mb-5">Profile</h2>

        <section className="flex flex-col gap-y-10 lg:gap-y-0 lg:flex-row">
          <div className="lg:w-3/12">
            <img src="/assets/images/wayne.svg" alt={user?.firstName} />
          </div>
          <div className="w-9/12 flex flex-col md:flex-row gap-y-5 md:gap-y-0 gap-x-5 lg:gap-x-20">
            <div className="flex gap-y-3 flex-col">
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Title</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.title}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">First Name</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.firstName}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Middle Name</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.middleName}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Surname</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.lastName}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Gender</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.gender}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Marital Status</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.maritalStatus}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Date Of Birth</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.dateOfBirth}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px]">Phone</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.phone}
                </p>
              </div>
            </div>
            <div className="flex gap-y-3 flex-col">
              <div className="flex items-center">
                <h4 className="min-w-[120px] md:min-w-[180px]">Email</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.email}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[120px] md:min-w-[180px]">Nationality</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.nationality}
                </p>
              </div>
              <div className="flex items-center">
                <h4 className="min-w-[180px]">Country of Residence</h4>
                <p className="px-2 py-1.5 border border-black border-opacity-10 rounded min-w-[150px]">
                  {user?.countryOfResidence}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 className="text-xl mt-14 mb-5">Visa History</h2>
          <table className="w-full table">
            <thead className="border-b-2 border-b-black text-sm md:text-base">
              <tr>
                <th className="py-2">Visa Category</th>
                <th className="py-2">Date Issued</th>
                <th className="py-2">Country Issued For</th>
                <th className="py-2">Entry Type</th>
                <th className="py-2">Expiry Date</th>
              </tr>
            </thead>
            <tbody className="text-sm md:text-base">
              {applications.map((application: any) => (
                <tr key={application._id} className="bg-white shadow-lg">
                  <td className="py-2 px-3">
                    {application?.visa?.name} ({application?.visa?.type} Visa)
                  </td>
                  <td className="py-2 px-3">{application.dateIssued}</td>
                  <td className="py-2 px-3">
                    {countries[application?.visa?.country]}
                  </td>
                  <td className="py-2 px-3">{application.entryType} Entry</td>
                  <td className="py-2 px-3">{application.expiry}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </Container>
  );
};
export default Profile;
