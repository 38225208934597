import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import {
  saveApplicationData,
  setCurrentUser,
  setCurrentUserAuthToken,
} from "helpers/storage";
import useAsync from "hooks/useAsync";
import useGetUser from "hooks/useGetUser";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { showDanger } from "utilities/misc";
import { get, post } from "utilities/network";
import countries from "utils/countries";

const Apply: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useGetUser();
  const [date, setDate] = useState<any>("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const isLoggedIn = Boolean(user?._id);

  const [password, setPassword] = useState("");
  const {
    execute: login,
    status,
    value,
  } = useAsync(
    () =>
      post(`auth/auth`, {
        email,
        password,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.user) {
      setCurrentUserAuthToken(value?.data?.accessToken);
      setCurrentUser({
        _id: value?.data?.user?._id,
        email: value?.data?.user?.email,
        firstName: value?.data?.user?.firstName,
        lastName: value?.data?.user?.lastName,
      });
      continueNow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [visa, setVisa] = useState<any>({});

  const visaID = new URLSearchParams(useLocation().search).get("visa") || "";

  const goBack = () => {
    navigate(`/${publicRoutes.search}`);
  };

  useEffect(() => {
    if (visaID) {
      search();
    } else {
      goBack();
    }
  }, [visaID]);

  const search = async () => {
    try {
      let result = await get(`visa/${visaID}`);

      if (result.visa) {
        setVisa(result.visa);
      } else {
        goBack();
      }
    } catch (e) {
      console.log(e);
      goBack();
    }
  };

  const continueNow = () => {
    if (!date) {
      return showDanger("date is not valid");
    }
    saveApplicationData({
      date,
      email,
      phone,
    });
    navigate(`/${publicRoutes.start}?visa=${visaID}`);
  };

  const processNow = () => {
    isLoggedIn ? continueNow() : login();
  };
  const goRegister = () => {
    saveApplicationData({
      visaID,
    });
    navigate(`/${publicRoutes.register}`);
  };

  return (
    <Container>
      <Header title="Visatify - Application Form" />
      {visa.name && (
        <main>
          <section className="flex flex-col items-center justify-center w-full mt-5 md:px-20">
            <h2 className="text-lg">Application Form</h2>
            <div className="bg-[#FD68001A] my-8 rounded-xl self-center mx-2 px-5 py-3">
              <h4 className="text-lg text-black text-opacity-70">
                <i className="mr-3 text-base fa fa-circle-exclamation text-primary"></i>
                Notice
              </h4>
              <p className="mt-2 font-light text-black text-opacity-80">
                Applicants should start the process of obtaining a UK Tourist
                Visa 3 Months Prior their travel date
              </p>
            </div>

            {/* <div className="relative flex flex-col items-center justify-between px-5 py-3 mx-2 my-5 bg-white rounded-xl lg:px-10 md:flex-row">
            <h4 className="text-lg text-left text-black text-opacity-60 md:w-1/2">
              You have an ongoing Visa registration process
            </h4>
            <div className="flex items-center justify-end mt-5 md:w-1/2 md:mt-0">
              <button className="px-5 py-2 font-medium border-2 rounded-lg text-primary border-primary lg:px-10 border-opacity-70">
                Go to Application
              </button>
              <button className="bg-black bg-opacity-20 rounded text-center px-1.5 ml-16 absolute md:static top-5 right-4">
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div> */}
          </section>
          <section className="flex flex-col items-stretch justify-center px-5 pt-10 pb-20 lg:px-32 md:flex-row gap-x-3 lg:gap-x-5 gap-y-5 md:gap-y-0">
            <div className="flex flex-col shadow-lg drop-shadow-lg md:w-8/12">
              <div className="bg-[#34A9FE17] flex w-full py-2 md:py-4 px-5 lg:px-14 justify-between">
                <h3 className="font-medium text-black text-opacity-50 lg:text-xl">
                  Visa Category
                </h3>
                <h3 className="font-medium text-black text-opacity-50 lg:text-xl">
                  Visa Details
                </h3>
                <h3 className="hidden font-medium text-black text-opacity-50 lg:block lg:text-xl">
                  Validity Requirement
                </h3>
              </div>
              <div className="flex flex-wrap items-center justify-between w-full px-3 py-5 bg-white lg:py-10 lg:px-10 gap-x-4">
                <div>
                  <h4 className="mb-4 text-lg">{visa.name}</h4>
                  <img
                    src={`https://flagcdn.com/112x84/${visa.country.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/224x168/${visa.country.toLowerCase()}.png 2x`}
                    width="112"
                    height="84"
                    alt={countries[visa.country]}
                  ></img>
                </div>
                <div className="text-left">
                  <div className="mb-4">
                    <h4 className="mb-1 text-lg text-black text-opacity-50">
                      Validity Period
                    </h4>
                    <p>
                      <i className="mr-2 fa fa-calendar-days text-primary text-opacity-70"></i>
                      <span className="text-black text-opacity-70">
                        {visa.validity} {visa.validityUnit}s after issuance
                      </span>
                    </p>
                  </div>
                  <div className="mb-4">
                    <h4 className="mb-1 text-lg text-black text-opacity-50">
                      Number of Entries
                    </h4>
                    <p>
                      <i className="mr-2 fa fa-refresh text-primary text-opacity-70"></i>
                      <span className="text-black text-opacity-70">
                        {visa.entryType} Entries
                      </span>
                    </p>
                  </div>
                  <div className="mb-4">
                    <h4 className="mb-1 text-lg text-black text-opacity-50">
                      Stay
                    </h4>
                    <p>
                      <i className="mr-2 fa fa-clock text-primary text-opacity-70"></i>
                      <span className="text-black text-opacity-70">
                        {visa.stay} {visa.stayUnit}s per entry
                      </span>
                    </p>
                  </div>
                </div>
                <div className="bg-[#34A9FE17] lg:hidden mb-2 flex w-full py-4 px-5 lg:px-14 justify-between">
                  <h3 className="block font-medium text-black text-opacity-50 lg:hidden lg:text-xl">
                    Validity Requirement
                  </h3>
                </div>
                <div className="text-left">
                  {visa.requirements?.map((requirement: string) => (
                    <h4 key={requirement} className="mb-4 text-lg">
                      <i className="mr-2 text-base fa fa-book-bookmark text-primary text-opacity-70"></i>
                      <span className="text-black text-opacity-70">
                        {requirement}
                      </span>
                    </h4>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-white py-5 px-5 items-center md:w-4/12 text-left h-[360px] shadow-lg drop-shadow-lg">
              <h3 className="mb-5 text-lg font-medium text-black">
                Product Summary
              </h3>
              <div className="flex justify-between px-2 pb-3 border-b border-b-black border-opacity-20">
                <h4 className="text-black text-opacity-50">{visa.name}</h4>
                <h4 className="text-black text-opacity-50">Price</h4>
              </div>
              <div className="flex justify-between px-2 pb-3 mt-2">
                <h4 className="text-black text-opacity-50">
                  Government Issuing
                </h4>
                <h4 className="font-medium text-black text-opacity-60">
                  ${visa.governmentFee}
                </h4>
              </div>
              <div className="flex justify-between px-2 pb-3 mt-2 border-b border-b-black border-opacity-20">
                <h4 className="text-black text-opacity-50">Processing fee</h4>
                <h4 className="font-medium text-black text-opacity-60">
                  ${visa.processingFee}
                </h4>
              </div>
              <div className="flex justify-between px-2 pb-3 mt-2">
                <h4 className="text-black text-opacity-50">Total Cost </h4>
                <h4 className="font-medium text-black text-opacity-60">
                  ${visa.governmentFee + visa.processingFee}
                </h4>
              </div>
              <div className="mt-5 text-center">
                <button
                  onClick={processNow}
                  className="py-2 text-white rounded shadow-lg bg-primary px-14 lg:px-20 bg-opacity-80 drop-shadow-lg"
                >
                  Continue
                </button>
              </div>
            </div>
          </section>

          <section className="px-5 mb-20 lg:px-32">
            <form
              method="POST"
              className="px-10 py-10 text-left bg-white shadow-lg drop-shadow-lg"
            >
              <h3 className="mb-10 text-lg font-medium text-black">
                When do you want to travel?
              </h3>

              <div className="mb-10">
                <p>When do you want to travel?</p>
                <input
                  className="px-5 py-2 mt-3 border border-black rounded outline-none border-opacity-30 md:w-1/3 lg:w-1/4"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-y-7 md:gap-y-0 md:items-center gap-x-5 ">
                {/* <div className="md:w-1/3 lg:w-1/4">
                  <p className="mb-2">Mobile Number</p>
                  <PhoneInput
                    country={"ng"}
                    enableSearch
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                      className:
                        "py-2 px-10 border border-black border-opacity-20 rounded outline-none w-full",
                    }}
                  />
                </div> */}
                {!isLoggedIn && (
                  <>
                    <div className="md:w-1/3 lg:w-1/4">
                      <p className="mb-2">Email</p>
                      <input
                        className="w-full px-5 py-2 border border-black rounded outline-none border-opacity-30"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="md:w-1/3 lg:w-1/4">
                      <p className="mb-2">Password</p>
                      <input
                        className="w-full px-5 py-2 border border-black rounded outline-none border-opacity-30"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* {!isLoggedIn && (
                <div className="mt-5">
                  Don't have an account?{" "}
                  <button
                    type="button"
                    className="text-success"
                    onClick={goRegister}
                  >
                    Sign Up
                  </button>
                </div>
              )} */}
              <div className="mt-10 text-center">
                <button
                  type="button"
                  onClick={processNow}
                  className="px-20 py-2 text-white rounded shadow-lg bg-primary bg-opacity-80 drop-shadow-lg"
                >
                  Proceed
                </button>
              </div>
            </form>
          </section>
        </main>
      )}
      <Footer />
    </Container>
  );
};

export default Apply;
