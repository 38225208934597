import Sidebar from "components/ui/Sidebar";
import useGetUser from "hooks/useGetUser";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { publicRoutes } from "routes/routes";

const DashboardHeader: React.FC<{ title: string }> = ({ title }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useGetUser();
  return (
    <header className="relative">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section className="flex justify-between md:ml-[12%] py-3 px-5 items-center border-b border-b-black border-opacity-20">
        <a
          href={`/${publicRoutes.search}`}
          className="px-3 py-2 text-white rounded shadow bg-primary lg:px-10 md:px-5 drop-shadow-lg"
        >
          New Application
        </a>
        <div className="flex items-center gap-x-5">
          <i className="text-black text-opacity-50 fa fa-bell"></i>
          <span className="hidden md:inline">{`${user.firstName} ${user.lastName}`}</span>
          <img
            src="/assets/images/wayne.svg"
            alt={`${user.firstName} ${user.lastName}`}
          />
          <button
            onClick={() => setShowMenu((p) => !p)}
            type="button"
            className="md:hidden fa fa-bars"
          ></button>
        </div>
      </section>
      <Sidebar showMenu={showMenu} />
    </header>
  );
};

export default DashboardHeader;
