import { get } from "utilities/network";

export const httpFetchUsers = (limit: number, search: string) => {
  return get(
    `admin/user/fetch?limit=${limit}${search ? "&search=" + search : ""}`
  );
};

export const httpFetchStats = () => {
  return get(`admin/user/stats`);
};
