const USER_STORAGE_KEY = "user";
const USER_TOKEN_STORAGE_KEY = "userToken";
const ADMIN_TOKEN_STORAGE_KEY = "AdminToken";
const ADMIN_STORAGE_KEY = "Admin";

const APPLICATION_KEY = "APPLICATION_KEY";

const saveData = (key: string, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

const getData = (key: string) => JSON.parse(localStorage.getItem(key)!);

export const currentUser = () => getData(USER_STORAGE_KEY);
export const currentAdmin = () => getData(ADMIN_STORAGE_KEY);

export const setCurrentUser = (user: any) => saveData(USER_STORAGE_KEY, user);
export const setCurrentAdmin = (admin: any) =>
  saveData(ADMIN_STORAGE_KEY, admin);

export const removeCurrentUser = () =>
  localStorage.removeItem(USER_STORAGE_KEY);
export const removeCurrentAdmin = () =>
  localStorage.removeItem(ADMIN_STORAGE_KEY);

export const currentUserAuthToken = () => getData(USER_TOKEN_STORAGE_KEY);
export const currentAdminAuthToken = () => getData(ADMIN_TOKEN_STORAGE_KEY);

export const setCurrentUserAuthToken = (token: string) =>
  saveData(USER_TOKEN_STORAGE_KEY, token);
export const setCurrentAdminAuthToken = (token: string) =>
  saveData(ADMIN_TOKEN_STORAGE_KEY, token);

export const removeCurrentUserAuthToken = () =>
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
export const removeCurrentAdminAuthToken = () =>
  localStorage.removeItem(ADMIN_TOKEN_STORAGE_KEY);

export const saveApplicationData = (data: any) => {
  sessionStorage.setItem(APPLICATION_KEY, JSON.stringify(data));
};
export const getApplicationData = () => {
  let data = sessionStorage.getItem(APPLICATION_KEY);
  return data !== null ? JSON.parse(data) : null;
};

export const clearApplicationData = () => {
  sessionStorage.removeItem(APPLICATION_KEY);
};

export {
  USER_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
  ADMIN_STORAGE_KEY,
  ADMIN_TOKEN_STORAGE_KEY,
};
