import Container from "components/globals/Container";
import DashboardHeader from "components/globals/DashboardHeader";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { onFilePicked, toBase64 } from "utilities/file";
import { get, post } from "utilities/network";
import countries from "utils/countries";

const VisaDocuments: React.FC = () => {
  const navigate = useNavigate();
  const [application, setApplication] = useState<any>({});
  const documents = application?.documents || {};
  const requirements = application?.visa?.requirements || [];

  const refs: any = {};

  const { applicationID } = useParams();

  const goBack = () => {
    navigate(`/${publicRoutes.applications}`);
  };

  useEffect(() => {
    if (applicationID) {
      search();
    } else {
      goBack();
    }
  }, [applicationID]);

  const search = async () => {
    try {
      let result = await get(`visa/application/${applicationID}`);

      if (result.application) {
        setApplication(result.application);
      } else {
        goBack();
      }
    } catch (e) {
      console.log(e);
      goBack();
    }
  };

  const onUpload = async (e: any) => {
    let file = onFilePicked(e);
    if (file) {
      let base64 = await toBase64(file);
      console.log(base64);

      try {
        let result = await post(`visa/uploadDocument/${applicationID}`, {
          requirement: e.target.id,
          document: base64,
        });
        search();
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <Container>
      <DashboardHeader title="Visatify - Visa Document" />
      <main className="text-left md:ml-[12%] px-2 md:px-10 py-5">
        <section>
          <h2 className="pb-3 mt-5 mb-10 text-xl font-medium border-b-2">
            #Primary Applicant
          </h2>

          <section className="flex flex-col ml-4 gap-y-10 lg:gap-y-0 lg:flex-row">
            <div className="lg:w-3/12">
              <img
                src="/assets/images/wayne.svg"
                alt={application?.firstName}
              />
            </div>
            <div className="flex flex-col w-9/12 md:flex-row gap-y-5 md:gap-y-0 gap-x-5 lg:gap-x-20">
              <div className="flex flex-col gap-y-3">
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Title
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.title}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    First Name
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.firstName}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Middle Name
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.middleName}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Surname
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.lastName}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Marital Status
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.maritalStatus}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Travel Date
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {DateTime.fromISO(application?.travelDate).toFormat("DDD")}
                  </p>
                </div>
                {/* 
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Phone
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.phone}
                  </p>
                </div> */}
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Gender
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.gender}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px]">
                    Date Of Birth
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {DateTime.fromISO(application?.dateOfBirth).toFormat("DDD")}
                  </p>
                </div>
                {/* <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px] md:min-w-[180px]">
                    Email
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.email}
                  </p>
                </div> */}
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[180px]">
                    Country of Residence
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {countries[application?.countryOfResidence]}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px] md:min-w-[180px]">
                    Nationality
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {countries[application?.nationality]}
                  </p>
                </div>
                <div className="flex items-center">
                  <h4 className="text-black text-opacity-50 min-w-[120px] md:min-w-[180px]">
                    Visa Type
                  </h4>
                  <p className="px-2 py-1.5  rounded min-w-[150px]">
                    {application?.visa?.type} Visa
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="py-5 my-10 border-t-2">
            <h2 className="text-xl font-medium ">Requirement(s)</h2>
            <p className="italic text-black text-opacity-50">
              Note that requirements varies based on visa type
            </p>
          </div>

          <div className="flex flex-col gap-y-5">
            {requirements.map((requirement: string) => (
              <div
                key={requirement}
                className="flex items-center justify-between px-5 py-5 border-black rounded border-opacity-20 border-y"
              >
                <div>
                  <h3 className="mb-1 md:text-lg">{requirement}</h3>
                </div>
                <div className="text-center">
                  {Boolean(documents[requirement]) && (
                    <button className="inline-block mt-2 mr-5 font-light md:mr-10">
                      Manage
                    </button>
                  )}
                  <input
                    onChange={onUpload}
                    type="file"
                    name="document"
                    className="hidden"
                    id={requirement}
                    ref={(ref) => (refs[requirement] = ref)}
                  />
                  <button
                    onClick={() => refs[requirement].click()}
                    disabled={Boolean(documents[requirement])}
                    className="px-5 py-2 border border-black rounded-lg border-opacity-30"
                  >
                    <span> Upload</span>{" "}
                    <i className="ml-2 fa fa-cloud-arrow-up text-primary"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Container>
  );
};
export default VisaDocuments;
