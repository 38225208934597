import { useLocation } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const AdminSidebar: React.FC<{ showMenu: boolean }> = ({ showMenu }) => {
  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1];
  return (
    <aside
      className={`bg-black bg-opacity-80 text-white h-screen w-full md:w-[12%] fixed overflow-y-hidden shadow-lg py-10 text-left mt-10 md:mt-0 overflow-hidden top-8 md:top-0 z-50 ${
        showMenu ? "block" : "hidden md:block"
      }`}
    >
      <h2 className="hidden text-xl font-black text-center md:block">
        <img
          src="/assets/images/visatify.png"
          className="object-contain w-32 ml-2"
          alt="Visatify"
        />
      </h2>

      <ul className="flex flex-col items-center mt-10 text-left md:items-start gap-y-10">
        <li
          className={`${
            currentPage === adminRoutes.dashboard
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === adminRoutes.dashboard ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={adminRoutes.dashboard}
          >
            <i className="mr-2 fa fa-home lg:mr-4"></i>
            Dashboard
          </a>
        </li>
        <li
          className={`${
            currentPage === adminRoutes.users
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === adminRoutes.users ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${adminRoutes.users}`}
          >
            <i className="mr-2 fa fa-user lg:mr-4"></i>
            Users
          </a>
        </li>
        <li
          className={`${
            currentPage === adminRoutes.applications
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === adminRoutes.applications ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${adminRoutes.applications}`}
          >
            <i className="mr-2 fa fa-box lg:mr-4"></i>
            Visa Applications
          </a>
        </li>
        <li
          className={`${
            currentPage === adminRoutes.visas
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === adminRoutes.visas ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${adminRoutes.visas}`}
          >
            <i className="mr-2 fa fa-box-open lg:mr-4"></i>
            Visas
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default AdminSidebar;
