import useGetAdmin from "hooks/useGetAdmin";
import useGetSubdomain from "hooks/useGetSubdomain";
import useGetUser from "hooks/useGetUser";
import {
  Navigate,
  Routes as ReactRoutes,
  Route,
  useLocation,
} from "react-router-dom";

import { adminRoutes, publicRoutes, subdomainRoutes } from "routes/routes";
import AdminLogin from "screens/ADMIN/Login";
import AdminRegister from "screens/ADMIN/Register";
import AdminDashboard from "screens/ADMIN/dashboard";
import AddVisa from "screens/ADMIN/dashboard/AddVisa";
import Applications from "screens/ADMIN/dashboard/Applications";
import EditVisa from "screens/ADMIN/dashboard/EditVisa";
import UserApplications from "screens/ADMIN/dashboard/UserApplications";
import Users from "screens/ADMIN/dashboard/Users";
import ViewApplication from "screens/ADMIN/dashboard/ViewApplication";
import Visas from "screens/ADMIN/dashboard/Visas";
import Apply from "screens/Apply";
import Home from "screens/Home";
import Login from "screens/Login";
import Payment from "screens/Payment";
import Register from "screens/Register";
import SearchResults from "screens/SearchResults";
import Start from "screens/Start";
import Dashboard from "screens/dashboard";
import VisaStatus from "screens/dashboard/Applications";
import Profile from "screens/dashboard/Profile";
import VisaDocuments from "screens/dashboard/VisaDocuments";

export function RequireAuth({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { user } = useGetUser();

  const location = useLocation();

  if (!user?._id) {
    return (
      <Navigate to={`/${publicRoutes.login}`} state={{ from: location }} />
    );
  }

  return children;
}

export function RequireAuthAdmin({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { admin } = useGetAdmin();

  const location = useLocation();

  if (!admin?._id) {
    return <Navigate to={`/${adminRoutes.login}`} state={{ from: location }} />;
  }

  return children;
}

const Switch = () => {
  const { subdomain, tldExists } = useGetSubdomain();

  switch (subdomain) {
    case tldExists ? subdomainRoutes.default : undefined:
      return (
        <>
          <Route path={publicRoutes.home} element={<Home />} />
          <Route path={publicRoutes.login} element={<Login />} />
          <Route path={publicRoutes.register} element={<Register />} />
          <Route path={publicRoutes.search} element={<SearchResults />} />
          <Route path={publicRoutes.apply} element={<Apply />} />
          <Route path={publicRoutes.start} element={<Start />} />
          <Route path={publicRoutes.payment} element={<Payment />} />

          <Route
            path={publicRoutes.dashboard}
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path={publicRoutes.profile}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path={publicRoutes.applications}
            element={
              <RequireAuth>
                <VisaStatus />
              </RequireAuth>
            }
          />
          <Route
            path={`${publicRoutes.visaDocuments}/:applicationID`}
            element={
              <RequireAuth>
                <VisaDocuments />
              </RequireAuth>
            }
          />

          <Route path={"*"} element={<Home />} />
        </>
      );

    case subdomainRoutes.admin:
      return (
        <>
          <Route path={adminRoutes.login} element={<AdminLogin />} />
          <Route path={adminRoutes.register} element={<AdminRegister />} />
          <Route
            path={adminRoutes.dashboard}
            element={
              <RequireAuthAdmin>
                <AdminDashboard />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.visas}
            element={
              <RequireAuthAdmin>
                <Visas />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.applications}
            element={
              <RequireAuthAdmin>
                <Applications />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.users}
            element={
              <RequireAuthAdmin>
                <Users />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={adminRoutes.addVisa}
            element={
              <RequireAuthAdmin>
                <AddVisa />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={`${adminRoutes.editVisa}/:visaID`}
            element={
              <RequireAuthAdmin>
                <EditVisa />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={`${adminRoutes.userApplications}/:userID`}
            element={
              <RequireAuthAdmin>
                <UserApplications />
              </RequireAuthAdmin>
            }
          />
          <Route
            path={`${adminRoutes.viewApplication}/:applicationID`}
            element={
              <RequireAuthAdmin>
                <ViewApplication />
              </RequireAuthAdmin>
            }
          />

          <Route path={"*"} element={<AdminLogin />} />
        </>
      );
    default:
      return <Route path={"*"} element={<Home />} />;
  }
};

function Routes() {
  return <ReactRoutes>{Switch()}</ReactRoutes>;
}

export default Routes;
