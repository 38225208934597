import { useLocation } from "react-router-dom";
import { publicRoutes } from "routes/routes";

const Sidebar: React.FC<{ showMenu: boolean }> = ({ showMenu }) => {
  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1];
  return (
    <aside
      className={`bg-white md:bg-[#34A9FE17] h-screen w-full md:w-[12%] absolute overflow-y-hidden shadow-lg py-10 text-left mt-10 md:mt-0 overflow-hidden top-8 md:top-0 z-50 ${
        showMenu ? "block" : "hidden md:block"
      }`}
    >
      <h2 className="hidden text-xl font-black text-center md:block">
        <img
          src="/assets/images/visatify.png"
          className="object-contain w-32 ml-2"
          alt="Visatify"
        />
      </h2>

      <ul className="flex flex-col items-center mt-10 text-left md:items-start gap-y-10">
        <li
          className={`${
            currentPage === publicRoutes.dashboard
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === publicRoutes.dashboard ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${publicRoutes.dashboard}`}
          >
            <i className="mr-2 fa fa-home lg:mr-4"></i>
            Dashboard
          </a>
        </li>

        <li
          className={`${
            currentPage === publicRoutes.profile
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === publicRoutes.profile ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${publicRoutes.profile}`}
          >
            <i className="mr-2 fa fa-user lg:mr-4"></i>
            Profile
          </a>
        </li>

        <li
          className={`${
            currentPage === publicRoutes.applications
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === publicRoutes.applications ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={`/${publicRoutes.applications}`}
          >
            <i className="mr-2 fa fa-box lg:mr-4"></i>
            Applications
          </a>
        </li>

        {/* <li
          className={`${
            currentPage === publicRoutes.visaDocuments
              ? "border-x-[3px] border-x-primary"
              : ""
          }  px-3`}
        >
          <a
            className={`${
              currentPage === publicRoutes.visaDocuments ? "text-primary" : ""
            } md:text-sm lg:text-base`}
            href={publicRoutes.visaDocuments}
          >
            <i className="mr-2 fa fa-box-archive lg:mr-4"></i>
            Visa Documents
          </a>
        </li> */}
      </ul>
    </aside>
  );
};

export default Sidebar;
