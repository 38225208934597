import { httpFetchMyApplications } from "api/user/visas";
import Container from "components/globals/Container";
import DashboardHeader from "components/globals/DashboardHeader";
import useGetUser from "hooks/useGetUser";
import { useState } from "react";
import Calendar from "react-calendar";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import countries from "utils/countries";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useGetUser();
  const [date, setDate] = useState<any>(new Date());
  const {
    data: { applications },
  } = useQuery("my-applications", () => httpFetchMyApplications(60), {
    initialData: { applications: [] },
  });

  const gotoApplication = (id: string) => {
    navigate(`/${publicRoutes.visaDocuments}/${id}`);
  };

  return (
    <Container>
      <DashboardHeader title="Visatify - Dashboard" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section className="flex flex-col mt-5 md:flex-row gap-y-10 md:gap-y-0 md:gap-x-5">
          <div className="md:w-[70%]">
            <div className="px-8 bg-gradient-to-b from-[#28007C] to-[#1783E1] shadow-lg drop-shadow-lg pt-8 pb-20 text-white rounded-xl max-h-[220px]">
              <h3 className="text-3xl font-light">Good Day,</h3>
              <h2 className="mt-2 text-4xl font-medium">{user.firstName}</h2>

              <p className="font-thin mt-7">
                Your travel plans and Visa application are in top gear &nbsp;
                <b>RELAX</b>
              </p>
            </div>
            <div className="w-full py-8 mt-10 text-black bg-white rounded-2xl">
              <h3 className="px-8 pb-4 text-2xl font-bold text-black border-b-2 text-opacity-70">
                Visa(s)
              </h3>

              <table className="table w-full">
                <thead className="text-sm md:text-base">
                  <tr className="font-light border-b">
                    <td className="px-2 py-4">Country</td>
                    <td className="px-2 py-4">Visa type</td>
                    <td className="px-2 py-4">Status</td>
                  </tr>
                </thead>
                <tbody className="p-4 text-sm md:text-base">
                  {applications.map((application: any) => (
                    <tr
                      onClick={() => gotoApplication(application._id)}
                      key={application._id}
                      className="pb-2 border-b cursor-pointer"
                    >
                      <td className="px-4">
                        <img
                          src={`https://flagcdn.com/72x54/${application?.visa?.country.toLowerCase()}.png`}
                          alt={countries[application?.visa?.country]}
                        ></img>
                      </td>
                      <td className="flex items-center py-3 pl-2 font-light">
                        {application?.visa?.type} Visa <br />(
                        {countries[application?.visa?.country]})
                      </td>

                      <td className="py-3 font-light capitalize">
                        {application.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="md:w-[30%]">
            <div className="px-3 pt-2 mb-5 bg-white shadow-lg rounded-2xl pb-14">
              <Calendar
                next2Label={<span className="ml-5 fa fa-chevron-right"></span>}
                prev2Label={<span className="mr-5 fa fa-chevron-left"></span>}
                onChange={setDate}
                value={date}
                tileClassName="py-2"
              />
            </div>
            <div className="pt-5 mb-5 bg-white shadow-lg rounded-2xl pb-14">
              <h3 className="px-5 pb-3 text-lg font-medium text-black border-b-2 text-opacity-70">
                Activities
              </h3>
            </div>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default Dashboard;
