import Container from "components/globals/Container";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const {
    execute: register,
    status,
    value,
  } = useAsync(
    () =>
      post(`auth/register`, {
        firstName,
        lastName,
        email,
        password,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Registration Successful");
      navigate(`/${publicRoutes.login}`, { state: { registration: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <main className="bg-white h-screen flex flex-col px-5 md:px-0">
        <header>
          <Helmet>
            <title>Visatify - Register</title>
          </Helmet>

          <div className="text-left py-5 px-10">
            <a className="text-2xl font-bold" href="/">
              Visatify
            </a>
          </div>
        </header>
        <section className="mt-20 self-center border border-black border-opacity-20 px-5 py-16 flex items-center flex-col w-full md:w-[50%] lg:w-[40%] rounded">
          <h3 className="text-xl font-medium self-start ml-5">
            Getting Started
          </h3>
          <form
            action=""
            method="post"
            className="w-full mt-14 flex flex-col items-center"
          >
            <div className="w-full items-center justify-center  flex flex-col md:flex-row gap-x-0 md:gap-x-5">
              <div className="flex items-start flex-col w-[90%] md:w-[35%] mb-8">
                <label className="cursor-pointer" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="border border-black rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex items-start flex-col w-[90%] md:w-[35%] mb-8">
                <label className="cursor-pointer" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="border border-black rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full"
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="email">
                Email Address
              </label>
              <input
                className="border border-black rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%]">
              <label className="cursor-pointer" htmlFor="password">
                Password
              </label>
              <input
                className="border border-black rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mt-14  w-[90%] md:w-[70%]">
              <button
                type="button"
                onClick={register}
                className="py-2 w-full block px-16 bg-primary text-white rounded-lg"
              >
                Sign Up
              </button>
              <div className="mt-5">
                Already have an account?{" "}
                <a className="text-success" href={publicRoutes.login}>
                  Sign In
                </a>
              </div>
            </div>
          </form>
        </section>
      </main>
    </Container>
  );
};

export default Register;
