export const fileToBase64 = async (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const max_file_size_in_kb = 1024 * 1024 * 10;
const allowed_extensions = [
  "png",
  "jpeg",
  "jpg",
  "gif",
  "doc",
  "docx",
  "pdf",
  "xls",
  "xlsx",
  "mp4",
  "3gp",
  "txt",
  "csv",
  "msword",
];

const getFileSizeKB = (file_size: number) => {
  file_size = parseInt(String(file_size / 1024));
  return file_size;
};

const getFileType = (file: File) => {
  return (
    file?.type.split("/").pop() ||
    file?.name.split(".")[file?.name.split(".").length - 1]
  );
};

const arrToLowerCase = (arr: string[] = []) => {
  return arr.map((str) => str.toLowerCase());
};

export const changeFileName = (file: File, newName: string) => {
  return new File([file], newName, {
    // type: file.type,
    type: "text/plain",
    lastModified: file.lastModified,
  });
};

export const onFilePicked = (e: any) => {
  let file_size_kb;
  let file_type;

  let { files } = e.target;

  file_size_kb = getFileSizeKB(files[0].size);
  file_type = getFileType(files[0]).toLowerCase();

  if (max_file_size_in_kb && file_size_kb > max_file_size_in_kb) {
    alert(`Maximum allowed file size = ${max_file_size_in_kb} kb`);
    return false;
  }

  if (
    allowed_extensions &&
    !arrToLowerCase(allowed_extensions).includes(file_type)
  ) {
    alert(`Allowed file type = ${allowed_extensions}`);
    return false;
  }

  return files[0];
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const lastItemInArray = (arr: any[]) => arr[arr.length - 1];
