import Container from "components/globals/Container";
import { setCurrentAdmin, setCurrentAdminAuthToken } from "helpers/storage";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import { post } from "utilities/network";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    execute: login,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/auth/login`, {
        email,
        password,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.user) {
      setCurrentAdminAuthToken(value?.data?.accessToken);
      setCurrentAdmin({
        _id: value?.data?.user?._id,
        email: value?.data?.user?.email,
        fullName: value?.data?.user?.fullName,
      });
      navigate(`${adminRoutes.dashboard}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <Container>
      <main className="bg-black bg-opacity-80 text-white h-screen flex flex-col px-5 md:px-0">
        <header>
          <Helmet>
            <title>Visatify - Admin Login</title>
          </Helmet>

          <div className="text-left py-5 px-5 md:px-10">
            <a className="text-2xl font-bold" href="/">
              <img
                src="/assets/images/visatify.png"
                className="object-contain w-40"
                alt="Visatify"
              />
            </a>
          </div>
        </header>
        <section className="mt-20 self-center border border-white border-opacity-20 px-5 py-16 flex items-center flex-col w-full md:w-[50%] lg:w-[40%] rounded">
          {state?.registration ? (
            <div className="bg-green-500 bg-opacity-40 mt-5 mb-3 rounded-lg px-4 py-2 text-white">
              <p>Registration successful.</p>
            </div>
          ) : null}
          <h3 className="text-xl font-medium self-start ml-5">Admin Login</h3>
          <form
            action=""
            method="post"
            className="w-full mt-14 flex flex-col items-center"
          >
            <div className="flex items-start flex-col w-[90%] md:w-[70%] mb-8">
              <label className="cursor-pointer" htmlFor="email">
                Email Address
              </label>
              <input
                className="border border-white rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full text-black"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex items-start flex-col w-[90%] md:w-[70%]">
              <label className="cursor-pointer" htmlFor="password">
                Password
              </label>
              <input
                className="border border-white rounded border-opacity-20 outline-none mt-2 py-2 px-3 w-full text-black"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mt-14 w-[90%] md:w-[70%]">
              <button
                type="button"
                onClick={login}
                className="py-2 w-full block px-16 bg-primary text-white rounded-lg"
              >
                Log In
              </button>
            </div>
          </form>
        </section>
      </main>
    </Container>
  );
};

export default Login;
