export const publicRoutes = {
  home: "/",
  search: "visa-results",
  about: "about",
  apply: "apply",
  register: "register",
  start: "start",
  dashboard: "dashboard",
  payment: "payment",
  profile: "profile",
  applications: "applications",
  visaDocuments: "visa-documents",
  contact: "contact",
  login: "login",
  terms: "terms-and-conditions",
  privacy: "privacy-policy",
};

export const adminRoutes = {
  dashboard: "/",
  logout: "logout",
  login: "login",
  register: "register",
  visas: "visas",
  users: "users",
  applications: "apps",
  userApplications: "user-applications",
  viewApplication: "view-application",
  addVisa: "add-visa",
  editVisa: "edit-visa",
};

export const subdomainRoutes = {
  default: "",
  admin: "admin",
};
