import {
  ADMIN_STORAGE_KEY,
  ADMIN_TOKEN_STORAGE_KEY,
  currentAdminAuthToken,
  currentUserAuthToken,
  USER_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from "helpers/storage";
import axios from "Lib/Axios/index";
import { adminRoutes, subdomainRoutes } from "routes/routes";
import { parse } from "tldjs";
import { handleError, removeFalsyValues } from "./misc";

const baseURL = process.env.REACT_APP_BASE_URL;

const userToken = () => {
  const { protocol, host } = window.location;
  let data = parse(`${protocol}//${host}`);
  let subdomain = data?.subdomain?.replace("www.", "");

  switch (subdomain) {
    case data.tldExists ? subdomainRoutes.default : undefined:
      return currentUserAuthToken();
    case subdomainRoutes.admin:
      return currentAdminAuthToken();
    default:
      return currentUserAuthToken();
  }
};

export const logout = () => {
  const { protocol, host } = window.location;
  let data = parse(`${protocol}//${host}`);
  let subdomain = data?.subdomain?.replace("www.", "");

  switch (subdomain) {
    case data.tldExists ? subdomainRoutes.default : undefined:
      return logoutUser();
    case subdomainRoutes.admin:
      return logoutAdmin();
    default:
      return logoutUser();
  }
};

export const logoutUser = () => {
  localStorage.removeItem(USER_STORAGE_KEY);
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
};

export const logoutAdmin = () => {
  localStorage.removeItem(ADMIN_STORAGE_KEY);
  localStorage.removeItem(ADMIN_TOKEN_STORAGE_KEY);
  window.location.replace(adminRoutes.login);
};

export const post = (url: string, data: any) => {
  return axios
    .post(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }

      handleError(response);
      throw response.data;
    });
};

export const get = (url: string) =>
  axios
    .get(`${baseURL}${url}`, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })

    .then(({ data }) => data?.data)

    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      } else {
        handleError(response);
      }
      throw response.data;
    });

export const put = (url: string, data: any) =>
  axios
    .put(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });

export const deleteRequest = (url: string) =>
  axios
    .delete(`${baseURL}${url}`, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });

export const patch = (url: string, data: any) =>
  axios
    .patch(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });
