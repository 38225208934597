// import { httpGetAdmin } from "api/admin/auth";
import { httpGetUser } from "api/user/auth";
import { currentUser } from "helpers/storage";
import { useState } from "react";
import { useQuery } from "react-query";

const useGetUser = () => {
  const [user, setUser] = useState<any>(currentUser());

  const { refetch: refetchUser } = useQuery("user", httpGetUser, {
    initialData: { user: user },
    onSuccess: (data) => {
      setUser(data?.user);
    },
  });
  return { user, refetchUser };
};

export default useGetUser;
