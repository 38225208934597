import { httpFetchApplications } from "api/admin/visas";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import Status from "components/ui/Status";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const Applications: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search") || "";
  const {
    data: { applications },
  } = useQuery(
    ["applications", search],
    () => httpFetchApplications(60, search),
    {
      initialData: { applications: [] },
    }
  );

  const onChange = (event: any) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value });
  };

  const selectApp = (ap: any) => {
    navigate(`/${adminRoutes.viewApplication}/${ap._id}`);
  };

  return (
    <Container>
      <AdminHeader title="Visatify Admin - Applications" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5 relative">
        <section>
          <div className="flex flex-col items-start justify-center mt-5 mb-10 md:justify-between gap-y-5 gap-x-8 md:flex-row">
            <h2 className="text-xl">Applications</h2>
            <div className="flex w-full md:w-auto gap-x-2">
              <input
                name="search"
                value={search || ""}
                onChange={onChange}
                type="text"
                placeholder="Search Email/Name/Visa ID"
                className="px-4 py-2 w-full md:min-w-[400px]"
              />
            </div>
          </div>
          <div className="overflow-scroll">
            <table className="table w-full">
              <thead className="text-sm md:text-base">
                <tr className="text-white bg-primary">
                  <th className="px-2 py-2">User </th>
                  <th className="px-2 py-2">Visa </th>
                  <th className="px-2 py-2">Date Applied</th>
                  <th className="px-2 py-2">Date Issued</th>
                  <th className="px-2 py-2">Status</th>
                  <th className="px-2 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm md:text-base">
                {applications?.map((application: any) => (
                  <tr key={application._id} className="bg-white shadow-lg">
                    <td className="px-3 py-4">{`${
                      application?.user?.firstName || ""
                    } ${application?.user?.lastName || ""}`}</td>
                    <td className="px-3 py-4">{application.visa.name}</td>
                    <td className="px-3 py-4">
                      {DateTime.fromISO(application.regDate).toFormat("ff")}
                    </td>
                    <td className="px-3 py-4">
                      {application.dateIssued
                        ? DateTime.fromISO(application.dateIssued).toFormat(
                            "ff"
                          )
                        : ""}
                    </td>
                    <td className="px-3 py-4">
                      <Status status={application.status} />
                    </td>
                    <td className="px-3 py-4">
                      <button
                        onClick={() => selectApp(application)}
                        className="px-3 py-1 text-black border rounded text-opacity-70"
                        type="button"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default Applications;
