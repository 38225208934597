const Status: React.FC<{ status: string }> = ({ status }) => {
  const colr = () => {
    if (status === "awaiting payment") return "bg-orange-500";
    if (status === "approved") return "bg-green-500";
    if (status === "declined") return "bg-red-500";
    return "bg-primary";
  };

  return (
    <span className={`p-2 px-4 text-sm text-white rounded ${colr()}`}>
      {status}
    </span>
  );
};

export default Status;
