import { publicRoutes } from "routes/routes";

const Footer: React.FC = () => (
  <footer className="px-20 pt-10 pb-32 text-left text-white bg-secondary">
    <section className="flex flex-col md:flex-row gap-y-14 md:gap-x-40">
      <div className="lg:w-3/12 md:5/12">
        <h2>
          <a className="text-4xl font-bold" href="/">
            <img
              src="/assets/images/visatify.png"
              className="object-contain w-40"
              alt="Visatify"
            />
          </a>
        </h2>
        <p className="mt-5">
          File a simple online form and make payment online. We handle the rest
          and send your travel document
        </p>
      </div>
      <div className="lg:w-9/12 md:7/12">
        <ul className="flex flex-col items-left gap-x-10 text-[17px]">
          <li className="mb-5">
            <a href={publicRoutes.home}>Home</a>
          </li>
          <li className="mb-5">
            <a href="">Visa Programs</a>
          </li>
          <li className="mb-5">
            <a href="">Travel Insight</a>
          </li>
          <li className="mb-5">
            <a href="">Contact Us</a>
          </li>
        </ul>
      </div>
    </section>
  </footer>
);

export default Footer;
