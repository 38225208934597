import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { publicRoutes } from "routes/routes";

const Home: React.FC = () => {
  const [pickup, setPickup] = useState("");
  const [destination, setDestination] = useState("");

  return (
    <Container>
      <Header title="Visatify" />
      <main>
        <section className="flex flex-col xl:flex-row pb-14 justify-between lg:pl-40 lg:pr-20 items-center xl:items-end relative bg-[#f0e9f0] gap-x-20 px-5">
          <div className="w-full text-left pt-14 md:pt-20 xl:w-1/2">
            <h1 className="text-5xl md:text-7xl font-medium md:leading-[75px] mb-5">
              <span className="relative">
                Global Visas
                <img
                  src="/assets/icons/cov.svg"
                  className="absolute object-contain w-7 md:w-10 top-1 -right-6"
                  alt="cov"
                />
              </span>{" "}
              <br /> Made{" "}
              <span className="relative text-primary">
                Easy{" "}
                <img
                  src="/assets/images/red-underline.svg"
                  className="absolute right-0 object-contain"
                  alt="red underline"
                />
              </span>
            </h1>
            <p className="mb-8 font-light md:text-xl">
              Find and apply for the right visa to your destination in just a
              few clicks
            </p>
            <div className="relative w-full px-5 py-4 text-left text-white bg-primary rounded-2xl">
              {/* <h2 className="px-5 font-bold md:px-0">
                Search for Visa Information
              </h2> */}
              <div className="flex flex-col justify-center xl:items-end xl:flex-row gap-x-10 gap-y-5">
                <div className="xl:w-1/2">
                  <p className="mb-2">Your Citizenship</p>
                  <ReactFlagsSelect
                    selected={pickup}
                    onSelect={(code) => setPickup(code)}
                    placeholder="Select Country"
                    searchable
                    id="pickup"
                    countries={[]}
                    blacklistCountries
                    className="bg-white text-black [&>button]:border-none"
                  />
                </div>
                <div className="xl:w-1/2">
                  <p className="mb-2">Your Destination</p>
                  <ReactFlagsSelect
                    selected={destination}
                    onSelect={(code) => setDestination(code)}
                    placeholder="Select Country"
                    searchable
                    id="destination"
                    countries={[]}
                    blacklistCountries
                    className="bg-white text-black [&>button]:border-none"
                    selectButtonClassName="border-none"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-7 xl:justify-end">
                <a
                  href={`${publicRoutes.search}?country=${pickup}&destination=${destination}`}
                  className="bg-[#FF5A00] inline-flex items-center rounded px-10 md:px-24 py-3 justify-center"
                >
                  <i className="fa fa-search"></i>
                  <span className="ml-5">Get Started</span>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full mt-10 xl:mt-0 xl:w-1/2">
            <img
              src="/assets/images/red-circle.svg"
              className="absolute right-0 object-contain"
              alt="red circle"
            />
            <img
              src="/assets/images/landing-woman.png"
              className="lg:w-[550px] relative object-contain mt-10 z-10"
              alt="Woman"
            />
          </div>
          <img
            src="/assets/icons/yellow-star.svg"
            className="absolute w-6 object-contain top-2 right-[30%]"
            alt="yellow star"
          />
          <img
            src="/assets/icons/blue-star.svg"
            className="absolute w-6 object-contain top-[40%] right-[40%]"
            alt="blue star"
          />
          <img
            src="/assets/icons/red-star.svg"
            className="absolute w-6 object-contain bottom-5 right-[20%]"
            alt="red star"
          />
          <img
            src="/assets/icons/blue-stroke.svg"
            className="absolute w-28 object-contain bottom-0 right-[5%]"
            alt="blue stroke"
          />
          <img
            src="/assets/icons/yellow-stroke.svg"
            className="absolute w-28 object-contain bottom-5 right-[40%]"
            alt="yellow stroke"
          />
        </section>

        <section className="bg-[#34A9FE17] py-10 px-5 md:px-20 lg:px-40">
          <h3 className="text-lg font-medium text-title">As seen in </h3>
          <div className="flex flex-wrap items-center mt-8 gap-x-5 gap-y-5 justify-evenly">
            <img src="/assets/images/travelperk.svg" alt="Travel perk" />
            <img src="/assets/images/sap.svg" alt="SAP" />
            <img
              src="/assets/images/national-geographic.svg"
              alt="National 'geographic"
            />
            <img src="/assets/images/fedex-express.svg" alt="Fedex" />
            <img src="/assets/images/fly-emirates.svg" alt="Fly Emirates" />
          </div>
        </section>
        <section className="px-5 py-20 md:px-10 lg:px-40">
          <h2 className="relative flex flex-col items-center justify-center text-2xl font-medium text-title">
            <p className="bg-title h-[5px] w-[80%] md:w-[50%] lg:w-[40%] absolute mt-1"></p>
            <span className="bg-[#f5f5f5] z-10 px-4">Why Choose Us</span>
          </h2>

          <div className="grid items-center mt-10 lg:flex md:grid-cols-2 gap-y-5 md:gap-x-10">
            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[400px]">
              <img src="/assets/icons/hassle.svg" alt="Hassle free" />
              <h3 className="my-3 text-lg font-medium">Hassle Free</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                Avoid all the stress of dealing with complicated paperwork and
                government sites. Our Visa experts handle every paperwork and
                filings
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[400px]">
              <img src="/assets/icons/review.svg" alt="Document Review" />
              <h3 className="my-3 text-lg font-medium">Document Review</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                All applications are reviewed by our Visa Experts to ensure
                error free application
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[400px]">
              <img src="/assets/icons/security.svg" alt="Data Security" />
              <h3 className="my-3 text-lg font-medium">Data Security</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                We will never share your data with third parties. Your data is
                100% secure and only used for filing your application
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[400px]">
              <img
                src="/assets/icons/support.svg"
                alt="24/7 Customer Support"
              />
              <h3 className="my-3 text-lg font-medium">
                24/7 Customer Support
              </h3>
              <p className="text-sm font-light text-black text-opacity-70">
                Our customer support team are available every hour of the day to
                assist with any support you need
              </p>
            </div>
          </div>
        </section>

        <section className="px-5 py-10 md:px-10 lg:px-40">
          <h2 className="text-2xl font-medium text-title">
            Featured Destination
          </h2>
          <div className="grid items-center lg:flex md:grid-cols-2 gap-y-5 md:gap-x-10 mt-14">
            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex justify-center flex-col px-2 pt-3 pb-10 h-[440px] text-left">
              <img src="/assets/images/paris.png" alt="Paris" />
              <h3 className="my-3 text-lg font-medium">Paris</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                We will never share your data with third parties. Your data is
                100% secure and only used for filing your application
              </p>
              <button className="w-32 px-4 py-2 mt-4 text-white rounded-lg bg-primary">
                <span>More</span> <i className="ml-2 fa fa-arrow-right"></i>
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex justify-center flex-col px-2 pt-3 pb-10 h-[440px] text-left">
              <img src="/assets/images/paris.png" alt="Paris" />
              <h3 className="my-3 text-lg font-medium">Paris</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                We will never share your data with third parties. Your data is
                100% secure and only used for filing your application
              </p>
              <button className="w-32 px-4 py-2 mt-4 text-white rounded-lg bg-primary">
                <span>More</span> <i className="ml-2 fa fa-arrow-right"></i>
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex justify-center flex-col px-2 pt-3 pb-10 h-[440px] text-left">
              <img src="/assets/images/paris.png" alt="Paris" />
              <h3 className="my-3 text-lg font-medium">Paris</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                We will never share your data with third parties. Your data is
                100% secure and only used for filing your application
              </p>
              <button className="w-32 px-4 py-2 mt-4 text-white rounded-lg bg-primary">
                <span>More</span> <i className="ml-2 fa fa-arrow-right"></i>
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-xl drop-shadow-lg lg:w-1/4 flex justify-center flex-col px-2 pt-3 pb-10 h-[440px] text-left">
              <img src="/assets/images/paris.png" alt="Paris" />
              <h3 className="my-3 text-lg font-medium">Paris</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                We will never share your data with third parties. Your data is
                100% secure and only used for filing your application
              </p>
              <button className="w-32 px-4 py-2 mt-4 text-white rounded-lg bg-primary">
                <span>More</span> <i className="ml-2 fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div className="mt-16 md:mt-24">
            <a
              href={publicRoutes.register}
              className="px-20 py-3 text-white rounded shadow-lg bg-primary drop-shadow-lg"
            >
              Get Started
            </a>
          </div>
        </section>
        <section className="py-20 px-5 md:px-10 lg:px-40 bg-[#EDF7FF] mt-16 md:mt-20">
          <h2 className="relative flex flex-col items-center justify-center text-2xl font-medium text-title">
            <p className="bg-title h-[5px] w-[80%] md:w-[50%] lg:w-[40%] absolute mt-1"></p>
            <span className="bg-[#EDF7FF] z-10 px-4">How It Works</span>
          </h2>

          <div className="grid items-center lg:flex md:grid-cols-2 gap-y-5 md:gap-x-10 mt-14">
            <div className="bg-white rounded-lg border-black border-l border-r-[3px] border-t border-b-[3px] lg:w-1/3 flex items-center justify-center flex-col px-10 py-8 h-[200px]">
              <h3 className="my-3 text-lg font-medium">For Electronic Visas</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                File a simple online form and make payment online. We handle the
                rest and send your travel document
              </p>
            </div>

            <div className="bg-white rounded-lg border-black border-l border-r-[3px] border-t border-b-[3px] lg:w-1/3 flex items-center justify-center flex-col px-10 py-8 h-[200px]">
              <h3 className="my-3 text-lg font-medium">For Embassy Visas</h3>
              <p className="text-sm font-light text-black text-opacity-70">
                Fill a simple online form, make payment online and choose your
                available interview date. We wil review your documents, advise
                were necessary and process your filings, You attend interview
                and obtain your Visa
              </p>
            </div>

            <div className="bg-white rounded-lg border-black border-l border-r-[3px] border-t border-b-[3px] lg:w-1/3 flex items-center justify-center flex-col px-10 py-8 h-[200px]">
              <h3 className="my-3 text-lg font-medium">
                For e-visa on arrival
              </h3>
              <p className="text-sm font-light text-black text-opacity-70">
                File a simple online form and make payment online. We handle the
                rest and send your travel document
              </p>
            </div>
          </div>
        </section>

        <section className="px-5 py-20 md:px-10 lg:px-40">
          <h2 className="relative flex flex-col items-center justify-center text-2xl font-medium text-title">
            <p className="bg-title h-[5px] w-[80%] md:w-[50%] lg:w-[40%] absolute mt-1"></p>
            <span className="bg-[#f5f5f5] z-10 px-4">Testimonials</span>
          </h2>

          <div className="grid items-center mt-10 lg:flex md:grid-cols-2 gap-y-5 md:gap-x-10">
            <div className="bg-white rounded-lg  border-black border-opacity-70 border-l border-r-[3px] border-t border-b-[3px] lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[300px]">
              <img src="/assets/images/wayne.svg" alt="Wayne" />
              <h3 className="mt-3 mb-2 italic font-medium">Wayne Ronald</h3>
              <p className="flex items-center justify-center">
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
              </p>
              <p className="mt-2 text-sm font-light text-black text-opacity-70">
                Lorem ipsum dolor sit amet consectetur. Consectetur enim sit
                dolor amet pellentesque massa integer donec. Non dignissim id
                viverra quis adipiscing facilisis neque vitae. Viverra auctor
                rhoncus molestie convallis commodo gravida pharetra imperdiet.
              </p>
            </div>

            <div className="bg-white rounded-lg  border-black border-opacity-70 border-l border-r-[3px] border-t border-b-[3px] lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[300px]">
              <img src="/assets/images/wayne.svg" alt="Wayne" />
              <h3 className="mt-3 mb-2 italic font-medium">Wayne Ronald</h3>
              <p className="flex items-center justify-center">
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
              </p>
              <p className="mt-2 text-sm font-light text-black text-opacity-70">
                Lorem ipsum dolor sit amet consectetur. Consectetur enim sit
                dolor amet pellentesque massa integer donec. Non dignissim id
                viverra quis adipiscing facilisis neque vitae. Viverra auctor
                rhoncus molestie convallis commodo gravida pharetra imperdiet.
              </p>
            </div>

            <div className="bg-white rounded-lg  border-black border-opacity-70 border-l border-r-[3px] border-t border-b-[3px] lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[300px]">
              <img src="/assets/images/wayne.svg" alt="Wayne" />
              <h3 className="mt-3 mb-2 italic font-medium">Wayne Ronald</h3>
              <p className="flex items-center justify-center">
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
              </p>
              <p className="mt-2 text-sm font-light text-black text-opacity-70">
                Lorem ipsum dolor sit amet consectetur. Consectetur enim sit
                dolor amet pellentesque massa integer donec. Non dignissim id
                viverra quis adipiscing facilisis neque vitae. Viverra auctor
                rhoncus molestie convallis commodo gravida pharetra imperdiet.
              </p>
            </div>

            <div className="bg-white rounded-lg  border-black border-opacity-70 border-l border-r-[3px] border-t border-b-[3px] lg:w-1/4 flex items-center justify-center flex-col px-5 py-10 h-[300px]">
              <img src="/assets/images/wayne.svg" alt="Wayne" />
              <h3 className="mt-3 mb-2 italic font-medium">Wayne Ronald</h3>
              <p className="flex items-center justify-center">
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
                <i className="fa fa-star text-[#FCD900]"></i>
              </p>
              <p className="mt-2 text-sm font-light text-black text-opacity-70">
                Lorem ipsum dolor sit amet consectetur. Consectetur enim sit
                dolor amet pellentesque massa integer donec. Non dignissim id
                viverra quis adipiscing facilisis neque vitae. Viverra auctor
                rhoncus molestie convallis commodo gravida pharetra imperdiet.
              </p>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Container>
  );
};
export default Home;
