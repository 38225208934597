import { httpGetApplication } from "api/admin/visas";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import Status from "components/ui/Status";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import countries from "utils/countries";

const ViewApplication: React.FC = () => {
  const { applicationID } = useParams();

  const {
    data: { application },
  } = useQuery(
    ["view-application", applicationID],
    () => httpGetApplication(applicationID as string),
    {
      initialData: { application: {} },
    }
  );

  return (
    <Container>
      <AdminHeader title="Visatify Admin - Applications" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5 relative">
        <section>
          <div className="flex items-center justify-between mt-5 mb-10">
            <h2 className="text-xl">View Application</h2>
            <div className=""></div>
          </div>
          <div className="overflow-scroll">
            <table className="table w-full">
              <thead className="text-sm md:text-base">
                <tr className="text-white bg-primary">
                  <th className="px-2 py-2">User </th>
                  <th className="px-2 py-2">Visa </th>
                  <th className="px-2 py-2">Date Applied</th>
                  <th className="px-2 py-2">Date Issued</th>
                  <th className="px-2 py-2">Status</th>
                  <th className="px-2 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm md:text-base">
                <tr className="bg-white shadow-lg">
                  <td className="px-3 py-4">{`${
                    application?.user?.firstName || ""
                  } ${application?.user?.lastName || ""}`}</td>
                  <td className="px-3 py-4">{application?.visa?.name}</td>
                  <td className="px-3 py-4">
                    {DateTime.fromISO(application?.regDate).toFormat("ff")}
                  </td>
                  <td className="px-3 py-4">
                    {application.dateIssued
                      ? DateTime.fromISO(application.dateIssued).toFormat("ff")
                      : ""}
                  </td>
                  <td className="px-3 py-4">
                    <Status status={application.status} />
                  </td>
                  <td className="px-3 py-4">
                    <button
                      className="px-3 py-1 text-black border rounded text-opacity-70"
                      type="button"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section className="flex flex-col mt-10 ml-5 gap-x-10 gap-y-10 lg:gap-y-0 lg:flex-row">
          <div className="">
            <img src="/assets/images/wayne.svg" alt={application?.firstName} />
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Title
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.title}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                First Name
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.firstName}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Middle Name
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.middleName}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Surname
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.lastName}
              </p>
            </div>
            <h3 className="font-light">Documents</h3>
            {application?.visa?.requirements?.map((requirement: any) => (
              <div key={requirement} className="flex items-center">
                <h4 className="text-black font-medium text-opacity-70 min-w-[120px]">
                  {requirement}
                </h4>
                <p className="px-2 py-1.5 rounded min-w-[150px]">
                  {Boolean(application?.documents?.[requirement]) && (
                    <a
                      target="_blank"
                      className="text-blue-400"
                      href={application?.documents?.[requirement]}
                    >
                      View file
                    </a>
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Marital Status
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.maritalStatus}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Gender
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.gender}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Date Of Birth
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {DateTime.fromISO(application?.dateOfBirth).toFormat("DDD")}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px]">
                Travel Date
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {DateTime.fromISO(application?.travelDate).toFormat("DDD")}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[180px]">
                Country of Residence
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {countries[application?.countryOfResidence]}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px] md:min-w-[180px]">
                Nationality
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {countries[application?.nationality]}
              </p>
            </div>
            <div className="flex items-center">
              <h4 className="text-black text-opacity-50 min-w-[120px] md:min-w-[180px]">
                Visa Type
              </h4>
              <p className="px-2 py-1.5  rounded min-w-[150px]">
                {application?.visa?.type} Visa
              </p>
            </div>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default ViewApplication;
