import { httpFetchUsers } from "api/admin/user";
import AdminHeader from "components/globals/AdminHeader";
import Container from "components/globals/Container";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const Users: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search") || "";

  const {
    data: { users },
  } = useQuery(["users", search], () => httpFetchUsers(60, search), {
    initialData: { users: [] },
  });

  const onChange = (event: any) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value });
  };

  return (
    <Container>
      <AdminHeader title="Visatify Admin - Users" />
      <main className="text-left md:ml-[12%] px-5 md:px-10 py-5">
        <section>
          <div className="flex flex-col items-start justify-center mt-5 mb-10 md:justify-between gap-y-5 gap-x-8 md:flex-row">
            <h2 className="text-xl">Users</h2>
            <div className="flex w-full md:w-auto gap-x-2">
              <input
                name="search"
                value={search || ""}
                onChange={onChange}
                type="text"
                placeholder="Search Email/Name"
                className="px-4 py-2 w-full md:min-w-[400px]"
              />
            </div>
          </div>
          <div className="overflow-y-scroll">
            <table className="table w-full">
              <thead className="text-sm border-b-2 border-b-black md:text-base">
                <tr>
                  <th className="py-2">Full name</th>
                  <th className="py-2">Email</th>
                  <th className="py-2">Registration Date</th>
                  <th className="py-2">Applications</th>
                </tr>
              </thead>
              <tbody className="text-sm md:text-base">
                {users.map((user: any) => (
                  <tr key={user._id} className="bg-white shadow-lg">
                    <td className="px-3 py-2">{`${user.firstName} ${user.lastName}`}</td>
                    <td className="px-3 py-2">{user.email}</td>
                    <td className="px-3 py-2">
                      {DateTime.fromISO(user.regDate).toFormat("ff")}
                    </td>
                    <td className="px-3 py-2">
                      <a
                        className="text-primary"
                        href={`/${adminRoutes.userApplications}/${user._id}`}
                      >
                        View Apps.
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </Container>
  );
};
export default Users;
