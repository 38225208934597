import { toast } from "react-toastify";

export const removeFalsyValues = (obj: any) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (!newObj[key]) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const showSuccess = (message: string) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const showDanger = (message: string) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const handleError = (response: any) => {
  if (response?.data?.error && typeof response?.data?.error == "object") {
    return Object.keys(response?.data?.error).forEach((key) => {
      showDanger(response?.data?.error[key]);
    });
  }
  if (!response && !response?.data) return showDanger("Something went wrong");
  return showDanger(response?.data?.message);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const capitalize = (str: string) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}` : "";

export const validateEmail = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const validatePassword = (password: string) => password.length >= 6;
export const validateName = (name: string) => name.length >= 3;
export const NumericOnly = (number: any) => /^[0-9]*$/.test(number);

export const nextBox = (
  itemLength: number,
  boxLength: number,
  toNext: boolean = true
) => {
  let nextIndex = 0;
  if (toNext) {
    if (itemLength < boxLength - 1) {
      nextIndex = itemLength;
    } else {
      nextIndex = boxLength - 1;
    }
  } else {
    if (itemLength > 0) {
      nextIndex = itemLength - 1;
    }
  }

  return nextIndex;
};
export const frequencyTime: any = {
  daily: "day",
  weekly: "week",
  monthly: "month",
};

export const explorerLinkForAddress = (address: string, crypto: string) => {
  if (crypto === "btc")
    return `https://www.blockchain.com/btc/address/${address}`;
  if (crypto === "ltc")
    return `https://live.blockcypher.com/ltc/address/${address}`;
  if (crypto === "eth") return `https://etherscan.io/address/${address}`;
  if (crypto === "usdt") return `https://tronscan.org/#/address/${address}`;
  if (crypto === "trx") return `https://tronscan.org/#/address/${address}`;
  if (crypto === "bnb") return `https://bscscan.com/address/${address}`;
  return ``;
};

export const gradient = "bg-gradient-to-r from-gfrom to-gto";

export const theRate = (rates: number[], amount: number) => {
  if (amount >= 500) return rates[2];
  if (amount >= 100) return rates[1];
  return rates[0];
};
