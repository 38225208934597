import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Container from "components/globals/Container";
import Footer from "components/globals/Footer";
import Header from "components/globals/Header";
import VButton from "components/ui/VButton";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
import { showDanger, showSuccess } from "utilities/misc";
import { get, post } from "utilities/network";

const paymentMethods = ["card"];

const methods: any = {
  card: "Card Payment",
  paypal: "Paypal",
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const Payment: React.FC = () => {
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);

  const [application, setApplication] = useState<any>({});
  const [total, setTotal] = useState(1);

  const appId = new URLSearchParams(useLocation().search).get("appId") || "";

  const goBack = () => {
    navigate(`/${publicRoutes.search}`);
  };

  useEffect(() => {
    if (appId) {
      search();
    } else {
      goBack();
    }
  }, [appId]);

  const search = async () => {
    try {
      let result = await get(`visa/app/${appId}`);

      if (result.application) {
        setApplication(result.application);
        setTotal(result.total);
      } else {
        goBack();
      }
    } catch (e) {
      console.log(e);
      goBack();
    }
  };

  const isCard = paymentMethod === "card";

  return (
    <Container>
      <Header title="Visatify - Application Form" />
      {application?.visa?.name && (
        <main>
          <section className="py-20 px-5 lg:px-32 flex flex-col-reverse md:flex-row justify-center items-stretch gap-x-3 lg:gap-x-5 gap-y-5 md:gap-y-0">
            <div className=" bg-white py-10 px-5 shadow-lg w-full drop-shadow-lg text-left flex-col md:w-8/12 mb-20">
              <h3 className="text-black font-medium text-xl mb-5">
                Payment Details
              </h3>

              <div className="flex">
                {paymentMethods.map((method) => (
                  <button
                    onClick={() => setPaymentMethod(method)}
                    key={method}
                    type="button"
                    className={`py-3 px-5 border border-black border-opacity-20 w-40 text-black font-medium ${
                      method === paymentMethod ? "" : "text-opacity-30"
                    }`}
                  >
                    {methods[method]}
                  </button>
                ))}
              </div>

              {isCard && (
                <div>
                  <div className="flex gap-x-5 my-10">
                    <img
                      src="/assets/icons/visa.svg"
                      className="object-contain"
                      alt="visa"
                    />
                    <img
                      src="/assets/icons/mastercard.svg"
                      className="object-contain"
                      alt="mastercard"
                    />
                    <img
                      src="/assets/icons/american-express.svg"
                      className="object-contain"
                      alt="american-express"
                    />
                    <img
                      src="/assets/icons/union.svg"
                      className="object-contain"
                      alt="union"
                    />
                  </div>

                  <Elements stripe={stripePromise}>
                    <StripeCheckout />
                  </Elements>
                </div>
              )}
            </div>
            <div className="bg-white py-5 px-5 items-center md:w-4/12 text-left h-[360px] shadow-lg drop-shadow-lg">
              <h3 className="text-black font-medium text-lg mb-5">
                Product Summary
              </h3>
              <div className="flex justify-between border-b border-b-black border-opacity-20 pb-3 px-2">
                <h4 className="text-black text-opacity-50">
                  {application?.visa?.name}
                </h4>
                <h4 className="text-black text-opacity-50">Price</h4>
              </div>
              <div className="flex justify-between mt-2 pb-3 px-2">
                <h4 className="text-black text-opacity-50">
                  Government Issuing
                </h4>
                <h4 className="text-black text-opacity-60 font-medium">
                  ${application?.visa?.governmentFee}
                </h4>
              </div>
              <div className="flex justify-between mt-2 border-b border-b-black border-opacity-20 pb-3 px-2">
                <h4 className="text-black text-opacity-50">Processing fee</h4>
                <h4 className="text-black text-opacity-60 font-medium">
                  ${application?.visa?.processingFee}
                </h4>
              </div>
              <div className="flex justify-between mt-2 pb-3 px-2">
                <h4 className="text-black text-opacity-50">Total Cost </h4>
                <h4 className="text-black text-opacity-60 font-medium">
                  $
                  {application?.visa?.governmentFee +
                    application?.visa?.processingFee}{" "}
                  {total > 1 && <span> x {total}</span>}
                </h4>
              </div>
              <div className="text-center mt-5">
                <button
                  disabled
                  className="bg-primary px-14 lg:px-20 py-2 text-white rounded bg-opacity-80 shadow-lg drop-shadow-lg disabled:bg-gray-300"
                >
                  Continue
                </button>
              </div>
            </div>
          </section>
        </main>
      )}
      <Footer />
    </Container>
  );
};

const StripeCheckout: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    if (stripe !== null) {
      // @ts-ignore
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        metadata: {},
      });

      // console.log({ error, paymentMethod });

      if (error) {
        return showDanger(error?.message || "");
      }
      setLoading(true);
      payNow(paymentMethod);
    }
  };
  const appId = new URLSearchParams(useLocation().search).get("appId") || "";

  const payNow = async (paymentMethod: any) => {
    let res = await post(`visa/make-payment`, {
      paymentIntent: paymentMethod.id,
      type: "card",
      appId,
    });
    setLoading(false);
    if (res?.data?.success) {
      showSuccess("Payment Successful");
      navigate(`/${publicRoutes.dashboard}`);
    }
  };

  const CARD_OPTIONS: any = {};

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-5">
        <CardNumberElement
          className="w-full py-3 px-3 border border-black border-opacity-20"
          options={CARD_OPTIONS}
        />
      </div>
      <div className="flex gap-x-5 md:gap-x-10">
        <div className="w-1/2 py-3 px-3 border border-black border-opacity-20">
          <CardExpiryElement options={CARD_OPTIONS} />
        </div>
        <div className="w-1/2 py-3 px-3 border border-black border-opacity-20">
          <CardCvcElement options={CARD_OPTIONS} />
        </div>
      </div>
      <div className="text-center mt-14">
        <VButton
          type="submit"
          disabled={!stripe || !elements}
          status={loading ? "pending" : ""}
          className="bg-primary px-14 lg:px-20 py-2 text-white rounded bg-opacity-80 shadow-lg drop-shadow-lg"
        >
          Make Payment
        </VButton>
      </div>
    </form>
  );
};

export default Payment;
